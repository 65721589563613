import { FC, useEffect, useMemo, useCallback, useState } from "react"

import { Button } from "react-materialize"

import { useAppDispatch, } from "@/app/hooks"

import {
  updateCustomer,
} from "@/features/propzMedia/propzMediaSlice"

import "./style.sass"
import { denyNotifications } from '@/utils/internal functions'

import {
  actions,
  useTrackedStore,
  store,
} from '@assets/store'

import bellSVG from '@/assets/icons/bell.svg'
import backshadowSVG from '@/assets/icons/webpush_backshadow.svg'
import { pdcStore } from "@assets/store/pdcStore"
import { localStore } from "@/store/localStore"

interface Props {
  style?: any;
}
export const WebpushCard: FC<Props> = ({ style }) => {

  const dispatch = useAppDispatch()

  const customer = useTrackedStore().app.customer()

  //IntersectionObserver instance
  const observer = useMemo(() => {

    return window.IntersectionObserver ? new window.IntersectionObserver((entries) => {

      entries.forEach((entry) => {
        window.dataLayer.push({
          event: "push_notification_modal_card_view",
        })

        localStore.set.lastShowedWebpushCard(new Date().getTime())

        observer?.unobserve(entry.target)
      })

    }, {
      root: document.body,
      threshold: 0.9
    }) : null
  }, [])

  const cardRef = useCallback(node => {
    if (node != null && observer) {
      observer.observe(node)
    }
  }, [observer])

  const close = useCallback(() => {
    pdcStore.set.webpushCardInteracted(true)
    denyNotifications()
  }, [])

  const action = useCallback(() => {
    dispatch(updateCustomer(
      {
        webPushContactFlag: true
      }
    ))

    pdcStore.set.checkNotificationsPermission('push_notification_modal_card_click')

    pdcStore.set.webpushCardInteracted(true)

  }, [customer])

  useEffect(() => {
    return function cleanup() {
      observer?.disconnect()
    }
  }, [])

  return (
    <div
      className="webpush_card primary_background text_primary_background"
      ref={cardRef}
      {...style ? { style } : {}}
    >
      <div className="webpush_card_image" style={{ backgroundImage: `url(${bellSVG}), url(${backshadowSVG})` }} />

      <div className="webpush_card_header">
        Quer receber ofertas exclusivas para você?
        <div className="webpush_card_subheader">
          Ative as notificações e não perca estas vantagens!
        </div>
      </div>

      <Button flat node="button" waves="light" className="webpush_card_big_button common_text_button common_button_border_radius common_button_shadow primary_color_text"
        onClick={action}
      >
        Ative agora mesmo!
      </Button>

      <Button flat node="button" waves="light" className="webpush_card_deny_button text_primary_background"
        onClick={close}
      >
        Não quero receber ofertas exclusivas :(
      </Button>

    </div>
  )
}