import React, { FC, memo, } from 'react'

import { ReactComponent as Background_cloud } from "@assets/icons/background_cloud.svg"

import { ReactComponent as Primary_gear } from "./primary_gear.svg"

import { ReactComponent as Secondary_gear } from "./second_gear.svg"

import lamp from "./lampada/lampada.png"
import lamp_2x from "./lampada/lampada_2x.png"
import lamp_3x from "./lampada/lampada_3x.png"

import './index.sass'
type Props = {
  className?: string;
}

export const LampImage: FC<Props> = memo(({ className, }) => {
  return (
    <div className={`lamp_image align-self-center ${className ?? ''}`}>

      <Background_cloud className="background_cloud" style={{
        '--color-primary': 'rgba(169,190,213,0.3)'
      } as React.CSSProperties} />

      <img
        //@ts-ignore
        srcset={`${lamp} 320w, ${lamp_2x} 640w, ${lamp_3x} 960w`}
        sizes="(max-width: 346px) 320px, (max-width: 692px) 640px, (min-width: 692px) 960px"
        src={lamp}
        //alt="lamp image"
        className='lamp_img'
      />

      <Primary_gear className="primary_gear" />

      <Secondary_gear className="secondary_gear" />

    </div>
  )
})