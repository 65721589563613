import { FC, } from "react"

import { ReactComponent as BellSvg } from "./push_bell_switch.svg"

import './icon.sass'
import exclamation from '@assets/icons/white_exclamation.png'

type Props = {
  className: string;
}

export const BellIcon: FC<Props> = ({ className, }) => {

  return (
    <div className={`bell_icon_container ${className}`} style={{
      "--exclamation": `url(${exclamation})`
    } as React.CSSProperties}>
      <BellSvg />
    </div>
  )
}