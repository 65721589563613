import { FC, useEffect, useMemo, useCallback, useState } from "react"
import { useLocation } from "react-router-dom"

import { QueryLink } from '@/parts'

import { Modal, Button } from "react-materialize"

import { OffersCarousel } from '@/components'

import {
  actions,
  useTrackedStore,
} from '@assets/store'

import "./style.sass"

import type { Offer } from '@assets/types'

import { orderedPopupTimings } from '@assets/constants/orderedPopupTimings'

import { promotionWithQRCode } from '@assets/utils'

import {
  notificationsNotAvailable,
  showed_later_days_diff,
} from '@/utils/internal functions'

import {
  isCommonPromotion,
} from '@assets/functions'

import { pdcStore } from "@assets/store/pdcStore"
import { localStore } from "@/store/localStore"

export const RecentlyActivatedPromotionsModal: FC = () => {

  const { search, pathname } = useLocation()

  const webpushCardInteracted = pdcStore.use.webpushCardInteracted()

  const showSplashScreenPopup = pdcStore.use.showSplashScreenPopup()

  const mainPromotions = pdcStore.use.mainPromotions()

  const customer = useTrackedStore().app.customer()

  const registrationRedirectLink = useTrackedStore().app.registrationRedirectLink()

  const showRecentlyActivatedPromotionsModal = pdcStore.use.openRecentlyActivatedPromotionsModal()

  const lastClosedPopup = pdcStore.use.lastClosedPopup()

  const query = useMemo(() => new URLSearchParams(search), [search])

  const promotionIds = useMemo(() => query.get("promotionIds") || '', [])

  const promotions: Offer[] | undefined = useMemo(() => {

    return mainPromotions?.filter(item =>
      (item?.id)
      &&
      (
        (
          (
            promotionIds?.includes(item?.id)
            ||
            item?.tags?.includes('offerbait')
            ||
            promotionWithQRCode(item)
          )
          &&
          !(localStorage?.getItem('OfferWasInRecentlyActivatedPromotionsModal'))?.includes(item?.id)
        )

        ||

        (
          item.type == "PERSONALIZED"
          &&
          item.requiresActivation
          &&
          !item?.activated
        )

        ||

        (
          item?.requiresRegisterComplete
          &&
          (
            (
              customer?.isRegisterComplete ?
                !(localStorage?.getItem('OfferWasInRecentlyActivatedPromotionsModal'))?.includes(item.id)
                :
                new Date(item?.endDate).getTime() > new Date().getTime()
            )
          )
        )
      )
    )
      .sort((a: Offer, b: Offer) => {
        if (a?.requiresRegisterComplete && !customer?.isRegisterComplete) {
          return -1
        }
        return 0
      })

  }, [mainPromotions])

  const setViewedPromotions = useCallback(() => {
    if (localStorage && promotions) {

      let ls = localStorage.getItem('OfferWasInRecentlyActivatedPromotionsModal') || ''

      const promotionIds_requiresRegisterComplete = promotions
        ?.filter(item => item?.requiresRegisterComplete
          &&
          (
            customer?.isRegisterComplete
            && !ls?.includes(item.id)
          ))
        .map((i: Offer) => {
          return i.id || ''
        })
        .filter((i: string) => i.length)

      const offerbait_promotions = promotions
        ?.filter(i => i?.tags?.includes('offerbait'))
        .map(i => i.id || '')
        .filter(i => i.length)

      const promotionIds_fromQuery = promotionIds.split(',')
        .filter(promotionId => !ls?.includes(promotionId))
        .filter(i => i.length)

      const ids = [...promotionIds_requiresRegisterComplete, ...promotionIds_fromQuery, ...offerbait_promotions]
        .filter((e, i, a) => a.indexOf(e) == i)
        .join(',')

      localStorage.setItem('OfferWasInRecentlyActivatedPromotionsModal', `${ls}${ls ? ',' : ''}${ids || ''}`)

    }
  }, [promotions, customer, promotionIds])

  const closePopup = useCallback((wait?: boolean) => {

    pdcStore.set.openRecentlyActivatedPromotionsModal(false)

    setTimeout(() => {
      if (pdcStore.get.showed_popups_count() < window.parameters.allowed_popus_count) {
        //if can show one more -> switch last closed popup
        pdcStore.set.lastClosedPopup('RecentlyActivatedPromotionsModal')
      }
      setViewedPromotions()
    }, wait ? orderedPopupTimings.RecentlyActivatedPromotionsModal : 0)

  }, [])

  useEffect(() => {

    if (promotions && lastClosedPopup == 'StoreSelectModal') {

      if (promotions.length) {
        pdcStore.set.openRecentlyActivatedPromotionsModal(true)
        pdcStore.set.increment_showed_popups_count()
      }
      else {
        closePopup()
      }
    }
  }, [promotions, lastClosedPopup])

  const sendClickEvent = useCallback(() => {
    window.dataLayer.push({
      event: 'signup_completion_modal_button_click'
    })
  }, [])

  const popupActions = useMemo(() => {

    return customer?.isRegisterComplete ?
      [
        <QueryLink to='/' >
          <Button flat modal="close" node="button" waves="light" className="text_primary_background primary_background modal_one_last_button">
            Ver todas as minhas ofertas
          </Button>
        </QueryLink>
      ]

      :

      [
        <QueryLink
          to={registrationRedirectLink || '/'}
          onClick={sendClickEvent}
        >
          <Button flat modal="close" node="button" waves="light" className="text_primary_background primary_background"
            onClick={() => closePopup(true)}
          >
            Complete seu cadastro para desbloquear
          </Button>
        </QueryLink>
        ,
        <QueryLink to='/' >
          <Button flat modal="close" node="button" waves="light">
            Ver ofertas
          </Button>
        </QueryLink>
      ]

  }, [customer])

  const showWebpushCard = useMemo(() => showed_later_days_diff(localStore.get.lastShowedWebpushCard()), [])

  const popupOpened = useMemo(() => showRecentlyActivatedPromotionsModal
    && !showSplashScreenPopup
    && !pathname.includes('/cadastro')
    && !pathname.includes('webpush-pagina'), [showRecentlyActivatedPromotionsModal, showSplashScreenPopup, pathname])

  useEffect(() => {
    if (promotions) {
      if (
        popupOpened
        && customer
        && !customer?.isRegisterComplete
        && promotions.some(item => item.requiresRegisterComplete)
      ) {
        window.dataLayer.push({
          event: 'signup_completion_modal_view'
        })
      }
    }
  }, [popupOpened])

  return (
    <Modal
      actions={popupActions}
      id="recently-activated-promotions-modal"
      open={popupOpened}
      options={{
        dismissible: true,
        opacity: 0.5,
        onCloseEnd: () => closePopup(true)
      }}
    >

      <Button
        flat
        className="white close-button"

        onClick={() => closePopup(true)}
      >
        <i className="material-icons">close</i>
      </Button>

      <div className="header">
        Você acaba de desbloquear novas ofertas!
      </div>

      <div className="subheader">
        Volte regularmente a esta página para seguir desbloqueando mais vantagens.
      </div>

      {
        promotions?.length ?
          <OffersCarousel
            list={promotions}

            webpushCard={
              !Number(window.rawUser.webPushContactFlag)
              && showWebpushCard
              && !notificationsNotAvailable()
              && !webpushCardInteracted
            }
          />
          : ''
      }
    </Modal>
  )
}