import { createStore } from 'zustand-x'

import { createJSONStorage } from 'zustand/middleware'

//@ts-ignore
import clone from 'just-clone'
import { Promotions_scopes_key } from '@assets/types/promotions_scopes';

type SessionStore = {
  promotions_scope: Promotions_scopes_key;
  promotions_search: string | null;
}

const initialState = {
  promotions_scope: '',
  promotions_search: '',
} as SessionStore

export const sessionStore = createStore('app')(initialState, {
  persist: {
    enabled: true,
    name: 'pdc-session-store',
    storage: createJSONStorage(() => sessionStorage),
  }
})
  .extendActions((set, get, api) => ({
    reset: () => {
      Object.getOwnPropertyNames(set)
        .filter(n => ![
          'mergeState',
          'state'
        ].includes(n))
        //@ts-ignore
        .forEach(n => set[n](initialState[n]))
    },
  }))