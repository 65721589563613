import { parametersObjectToQueryString } from '@assets/functions/parametersObjectToQueryString'

import {
  sessionParameter,
} from '@assets/utils'

import { getApiRoot } from '@assets/functions/getApiRoot'

import { Offer } from '@assets/types'
import {
  V2_Promotions_Props,
  V2_Promotions_Query,
} from '@assets/types/v2_promotions'

export const v2_cxp_promotions = async (properties: V2_Promotions_Props): Promise<Offer[]> => {

  const {
    mechanics,
    types,
    ...rest_properties
  } = properties

  const q: V2_Promotions_Query = rest_properties

  if (mechanics) {
    q.mechanics = mechanics.join(',')
  }

  if (types?.length && mechanics?.length) {
    //if there is at least one mechanic and one type, request promotions
    q.types = types.join(',')

    const resp = await fetch(`${getApiRoot()}/v2/cxp/promotions/?${parametersObjectToQueryString(q)}${sessionParameter()}`, {
      method: "GET",
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
    })
    if (resp.status == 200) {
      return resp.json()
    }

  }
  return []

}