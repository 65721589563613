import { FC, useEffect, useMemo, useState, useCallback, useRef, memo, } from "react"

import { useHistory, useLocation } from "react-router-dom"

import { useAppDispatch, useAppSelector } from "@/app/hooks"

import { promotionEvent } from "@assets/utils/promotionEvent"

import { promotionEcomEvent } from "@assets/utils/promotionEcomEvent"

import { promotionListEcomEvent } from '@assets/utils/promotionListEcomEvent'

import { ReactComponent as Right_chevron_SVG } from '@assets/icons/right_chevron.svg'

import {
  trackEvent,
  trackOfferView,
} from "@/features/propzMedia/propzMediaSlice"

import {
  actions,
  useTrackedStore,
  useStore,
} from '@assets/store'

import {
  OffersCarousel,
  PromotionBarAppCTA,
  TopCarousel,
} from '@/components'

import { PromotionCard } from '@assets/components/PromotionCard'
import { TextInput } from "react-materialize"

import "./style.sass"

import { NoOffers } from "./components/NoOffers"

import { ReactComponent as CartSVG } from './assets/cart.svg'
import { ReactComponent as SearchSVG } from '@assets/icons/search.svg'
import { ReactComponent as CancelFilledSVG } from '@assets/icons/cancel_filled.svg'

import { promotionWithQRCode } from '@assets/utils/promotionWithQRCode'

import { itemIsStored } from '@assets/utils/storage'

import { pdcStore } from "@assets/store/pdcStore"
import { LoadingCircle } from "@assets/components/LoadingCircle"
import { useMediaQuery } from "usehooks-ts"
import { useApp } from "@assets/hooks/useApp"
import { add_to_cart } from '@assets/API/v1/add_to_cart'

import { v2_cxp_promotions } from '@assets/API/v2/v2_cxp_promotions'

import { v2_cxp_promotions_scopes } from '@assets/API/v2/v2_cxp_promotions_scopes'

import { useDebounceCallback } from "usehooks-ts"
import { filters } from "./filters"
import intersect from "intersect"
import { card_promotion_mechanics } from "@assets/constants/card_promotion_mechanics"
import { Offer } from "@assets/types"
import { LampImage } from '@assets/components/LampImage'
import { sessionStore } from "@/store/sessionStore"
import { Promotions_scopes, Promotions_scopes_key } from "@assets/types/promotions_scopes"

const observedTargets: string[] = []

let mouse_down = false
let scroll_target: null | HTMLDivElement

const promotions_scope_parameter = 'promotions_scope'
const promotions_search_parameter = 'promotions_search'

export const Offers: FC = memo(() => {

  const dispatch = useAppDispatch()

  const { search, pathname } = useLocation()
  const history = useHistory()

  const [promotion_filters_ref, set_promotion_filters_ref] = useState<HTMLDivElement | null>(null)

  const [active_filter, set_active_filter] = useState<undefined | typeof filters[number]>()

  const [search_promotions, set_search_promotions] = useState('')

  const [checked_query_first_time, set_checked_query_first_time] = useState(false)

  const [card_promotions, set_card_promotions] = useState<Offer[]>([])

  const [loading_filtered_promotions, set_loading_filtered_promotions] = useState(true)

  const {
    reminders,
  } = useAppSelector(
    (state) => state.propzMedia
  )

  const mouse_down_lstnr = useCallback((e: MouseEvent) => {
    if (!promotion_filters_ref) return;
    mouse_down = true;
    scroll_target = e.target as HTMLDivElement

    scroll_target.style.cursor = 'grabbing'

  }, [promotion_filters_ref])

  const mouse_up_lstnr = useCallback((e: MouseEvent) => {
    if (!promotion_filters_ref || !scroll_target) return;
    mouse_down = false;

    scroll_target.style.removeProperty('cursor')
    scroll_target = null
  }, [promotion_filters_ref])

  const mouse_move_lstnr = useCallback((e: MouseEvent) => {
    if (!mouse_down || !promotion_filters_ref) return;

    e.preventDefault();

    promotion_filters_ref.scrollLeft = Math.min(
      Math.max(0, promotion_filters_ref.scrollLeft - e.movementX),

    )
  }, [promotion_filters_ref])

  const wheel_lstnr = useCallback((e: WheelEvent) => {
    if (!promotion_filters_ref) return;

    e.preventDefault()

    promotion_filters_ref.scrollLeft = Math.min(
      Math.max(promotion_filters_ref.scrollLeft + e.deltaY, 0),

    )
  }, [promotion_filters_ref])

  useEffect(() => {
    //scroll handle for filters
    if (promotion_filters_ref && !('ontouchstart' in window)) {
      promotion_filters_ref.addEventListener('mousedown', mouse_down_lstnr)

      promotion_filters_ref.addEventListener('mouseup', mouse_up_lstnr)
      promotion_filters_ref.addEventListener('mouseleave', mouse_up_lstnr)

      promotion_filters_ref.addEventListener('mousemove', mouse_move_lstnr)

      promotion_filters_ref.addEventListener('wheel', wheel_lstnr)

      return () => {
        promotion_filters_ref.removeEventListener('mousedown', mouse_down_lstnr)
        promotion_filters_ref.removeEventListener('mouseup', mouse_up_lstnr)
        promotion_filters_ref.removeEventListener('mousemove', mouse_move_lstnr)
        promotion_filters_ref.removeEventListener('wheel', wheel_lstnr)
      }
    }
  }, [promotion_filters_ref])

  useEffect(() => {
    if (checked_query_first_time) {
      (async () => {
        set_loading_filtered_promotions(true)

        const storeId = window.rawUser.preferredStore || window.rawUser.registrationStoreId;

        const promotions = await v2_cxp_promotions(window.inIframe ?
          //virtual view
          {
            mechanics: intersect(card_promotion_mechanics, window.parameters.offers_mechanics_ecommerce),
            types: window.parameters.ecommerce_offers_types,
            options: 'storeIds',
            theme: 'none',
            ...!window.parameters.disable_promotions_search_bar_vv && search_promotions ? {
              description: search_promotions
            } : {},
            ...!window.parameters.disable_promotions_filters_vv && active_filter ? {
              scope: active_filter.filter
            } : {}
          }
          :
          //pdc
          {
            mechanics: intersect(card_promotion_mechanics, window.parameters.offers_mechanics),
            types: window.parameters.offers_types,
            options: 'storeIds',
            ...storeId ? { storeId } : {},
            theme: 'none',
            ...!window.parameters.disable_promotions_search_bar_pdc && search_promotions ? {
              description: search_promotions
            } : {},
            ...!window.parameters.disable_promotions_filters_pdc && active_filter ? {
              scope: active_filter.filter
            } : {}
          }
        )

        const upd_proms = window.inIframe ? promotions.map(i => {
          // remaining == -1 means unlimited
          i.remaining = (i.remaining < 0 ? 10000 : i.remaining);
          i.maxPerCustomer = (i.maxPerCustomer < 0 ? 10000 : i.maxPerCustomer)
          //to test Comprar button
          //i.url = 'https://google.com'

          return i
        }) : promotions

        set_card_promotions(upd_proms)

        set_loading_filtered_promotions(false)

      })();
    }
  }, [search_promotions, active_filter, checked_query_first_time])

  const search_promotions_ref = useRef<HTMLInputElement | null>(null)

  const showEcommerceCartSuccess = pdcStore.use.showEcommerceCartSuccess()

  const [show_promotion_bar_app_cta, _] = useApp('promotionbar')

  const two_promotions_in_row = useMediaQuery('(max-width: 874px)')

  const four_promotions_in_row = useMediaQuery('(min-width: 1241px)')

  const three_promotions_in_row = useMediaQuery('(min-width: 875px) and (max-width: 1240px)')

  const [search_input, set_search_input] = useState('')

  const [available_filters, set_available_filters] = useState<null | Promotions_scopes>(null)

  const quantity_of_promotions_in_two_rows = useMemo(() => {
    if (two_promotions_in_row) return 4
    if (three_promotions_in_row) return 6
    if (four_promotions_in_row) return 8
    return 8
  }, [
    two_promotions_in_row,
    three_promotions_in_row,
    four_promotions_in_row,
  ])

  const marketplaceEnable = pdcStore.use.marketplaceEnable()

  const mainPromotions = pdcStore.use.mainPromotions()

  const bannerPromotions = pdcStore.use.bannerPromotions()

  const stores = useStore().app.stores()

  const customer = useTrackedStore().app.customer()

  const ecommerceCart = pdcStore.use.ecommerceCart()

  const showRecentlyActivatedPromotionsModal = pdcStore.use.openRecentlyActivatedPromotionsModal()

  const query = useMemo(() => {
    return new URLSearchParams(search)
  }, [search])

  useEffect(() => {
    (async () => {

      const storeId = window.rawUser.preferredStore || window.rawUser.registrationStoreId;
      const available_filters = await v2_cxp_promotions_scopes(window.inIframe ? {
        mechanics: intersect(card_promotion_mechanics, window.parameters.offers_mechanics_ecommerce),
        types: window.parameters.ecommerce_offers_types,
        options: 'storeIds',
        theme: 'none',
      } : {
        mechanics: intersect(card_promotion_mechanics, window.parameters.offers_mechanics),
        types: window.parameters.offers_types,
        options: 'storeIds',
        ...storeId ? { storeId } : {},
        theme: 'none',
      })

      set_available_filters(available_filters)
    })()
  }, [])

  useEffect(() => {
    if (available_filters) {
      set_checked_query_first_time(checked => {

        let scope = query.get(promotions_scope_parameter) as Promotions_scopes_key

        if (scope && !available_filters.includes(scope)) {
          //redirect if current filter is anavailable
          query.delete(promotions_scope_parameter)
          history.replace({ pathname, search: query.toString() })

          //skip current check
          return false
        }

        let search = query.get(promotions_search_parameter)

        if (window.inIframe) {
          if (!checked) {
            //first time parameters will be read from session
            scope = sessionStore.get.promotions_scope()

            if (scope) {
              query.set(promotions_scope_parameter, scope)
              search = null
            } else {
              search = sessionStore.get.promotions_search()
              scope = null
              if (search) {
                query.set(promotions_search_parameter, search)
              }
            }

            history.replace({ pathname, search: query.toString() })

          } else {
            //other times parameters will be stored in session
            sessionStore.set.promotions_scope(scope)
            sessionStore.set.promotions_search(search)
          }
        }

        set_active_filter(scope ? filters.find(f => f.filter == scope) : undefined)

        let s = decodeURIComponent(search ?? '')
        set_search_promotions(s)
        set_search_input(s)

        return true
      })
    }
  }, [
    query,
    available_filters,
  ])

  //open promotion stores modal if there is promotionId in search
  const promotionId = useMemo(() => query.get("promotionId"), [query])

  const click_main_filter = useCallback(
    useDebounceCallback(
      () => {
        query.delete(promotions_scope_parameter)
        query.delete(promotions_search_parameter)
        history.push({ pathname, search: query.toString() })
      }
      , 50)
    , [pathname, query])

  const click_filter = useCallback(
    useDebounceCallback(
      (filter) => {
        if (active_filter?.filter == filter.filter) {
          query.delete(promotions_scope_parameter)
        } else {
          query.set(promotions_scope_parameter, filter.filter)
        }

        query.delete(promotions_search_parameter)

        history.push({ pathname, search: query.toString() })
      }
      , 50)
    , [pathname, query, active_filter])

  const promotions_filters = useMemo(() => (
    window.inIframe
      ?
      !window.parameters.disable_promotions_filters_vv
      :
      !window.parameters.disable_promotions_filters_pdc
  )
    ?
    <div className="promotion_filters" ref={e => set_promotion_filters_ref(e)}>
      <button className={`filter_button ${!active_filter && !search_promotions && checked_query_first_time ? 'active' : ''}`} onClick={click_main_filter} style={{
        order: 0
      }}>
        Todas as ofertas
      </button>

      {
        filters.map(filter =>
          available_filters?.includes(filter.filter) ?
            <button className={`filter_button ${filter.filter == active_filter?.filter && checked_query_first_time ? 'active' : ''}`}
              onClick={() => click_filter(filter)}
              key={filter.filter}
            >
              {filter.name}
            </button>
            : <></>
        )
      }
    </div>
    : <></>, [
    active_filter,
    search_promotions,
    available_filters,
    checked_query_first_time,
  ])

  useEffect(() => {
    if (promotionId && card_promotions) {
      const promo = card_promotions.find((item: any) => item.id == promotionId || item.partnerId == promotionId);
      if (promo) {
        actions.app.CurrentOfferStoresModalPromotion(promo)

        actions.app.currentOfferStoresModalOpened(true)
      }
    }
  }, [promotionId, card_promotions])

  //open promotion conditions modal if there is promotionCondition in search

  const promotionCondition = useMemo(() => query.get('promotionCondition'), [query])

  useEffect(() => {
    if (promotionCondition && card_promotions) {
      const item = card_promotions.find((item: any) => item.id == promotionCondition || item.partnerId == promotionCondition);
      if (item) {
        actions.app.OfferConditionsModalItem(item)
        actions.app.OfferConditionsModalOpened(true)
      }
    }
  }, [promotionCondition, card_promotions])

  const itemsToRenderAtOnce = 10

  //track last loaded Promotion number to render +10 of them
  const [lastLoadedPromotion, setLastLoadedPromotion] = useState(0)

  const renderedMainPromotions = useMemo(() => {

    return card_promotions?.slice(0, lastLoadedPromotion + itemsToRenderAtOnce)
  }, [lastLoadedPromotion, card_promotions])

  const [viewedItems, setViewedItems] = useState<HTMLElement[]>([])

  //IntersectionObserver instance
  const observer = useMemo(() => {

    return window.IntersectionObserver ? new window.IntersectionObserver((entries) => {

      entries.forEach(entry => {

        if (entry.isIntersecting) {
          const { numberin_list, promotion_id } = (entry.target as HTMLElement).dataset

          //watch last loaded number to add more offers to render
          setLastLoadedPromotion(lastLoadedPromotion => {
            return Math.max(Number(numberin_list), lastLoadedPromotion)
          })

          //viewed event track
          if (promotion_id) {

            let storedOffersViewed = itemIsStored(sessionStorage, 'offersViewed', promotion_id)

            let trackingIds = query.get('trackingIds')

            let promotion = pdcStore.get.mainPromotions()?.find(p => p.id == promotion_id)

            if (
              promotion?.requiresRegisterComplete
              && !customer.isRegisterComplete
            ) {
              setTimeout(() => {
                //setViewedItems(vi => [...vi, promotion_id])
                setViewedItems(vi => [...vi, entry.target as HTMLElement])
              }, 300)

            } else {
              promotionEcomEvent({
                event: 'offer-view-pdc',
              }, promotion)
            }

            if (!storedOffersViewed) {
              if (
                promotionWithQRCode(promotion)
              ) {
                dispatch(trackEvent({ category: 'passive_behavior', action: 'offer-view', property: promotion_id, label: 'digital' }))
              } else {
                dispatch(trackOfferView({ promotionIds: promotion_id, uid: window.rawUser?.uuid, trackingIds }))
              }
            }
          }
          observer?.unobserve(entry.target)
        }
      })
    }, {
      root: document.body,
      threshold: 0.9
    }) : null
  }, [])

  useEffect(() => {
    //to trigger event only after popup closes
    if (
      !showRecentlyActivatedPromotionsModal
      && viewedItems.length
      && !pathname.includes('/cadastro')
      && !pathname.includes('webpush-pagina')
    ) {
      viewedItems.forEach(card => {
        let storedLockedOffersViewed = sessionStorage.getItem('lockedOffersViewed') || ''

        const { promotion_id } = card.dataset

        if (promotion_id && !storedLockedOffersViewed.includes(promotion_id)) {

          let promotion = card_promotions?.find(promo => promo.id == promotion_id)

          const ctaText = card.querySelector('.locked_subheader')?.textContent

          promotionEvent({
            event: `signup_completion_offer_card_view`,
            ctaText,
          }, promotion)

          sessionStorage.setItem('lockedOffersViewed', `${storedLockedOffersViewed}${storedLockedOffersViewed ? ',' : ''}${promotion_id || ''}`)

          setViewedItems(vi =>
            vi.filter(i => card.dataset.promotion_id != promotion_id)
          )

        }
      })
    }
  }, [viewedItems, showRecentlyActivatedPromotionsModal])

  //refs to main cards price to track observation
  const [mainCardsRefs, setMainCardsRefs] = useState<Array<HTMLDivElement | null>>([])

  useEffect(() => {
    if (observer) {
      mainCardsRefs.forEach((el, i, a) => {
        if (el) {
          const { promotion_id } = el.dataset

          if (promotion_id && !observedTargets.includes(promotion_id)) {

            //add to observer only once per render for each el

            observedTargets.push(promotion_id)

            observer.observe(el)
          }
        }
      })
    }
  }, [mainCardsRefs, observer])

  useEffect(() => {
    return function cleanup() {
      observer?.disconnect()
      observedTargets.length = 0
    }
  }, [])

  const noPromotions = useMemo(() => {
    return (
      !mainPromotions?.length
      && !bannerPromotions?.length
      && !window.offersGroup?.length
      && !window.parameters.bannersList?.length
      && !window.parameters.bannersList?.length
      && !marketplaceEnable
      && !reminders?.length
    )
  }, [mainPromotions])

  //store to show at top line for favorite store
  const store_selected = useMemo(() => {
    if (customer?.preferredStore) {
      return stores?.find(store => store.storeId == customer?.preferredStore)
    }
    if (customer?.registrationStoreId) {
      return stores?.find(store => store.storeId == customer?.registrationStoreId)
    }
    return null
  }, [customer, stores])

  const ecom_button_disabled = useMemo(() => ecommerceCart.reduce((a, b) => a + b.quantity, 0) == 0, [ecommerceCart])

  useEffect(() => {
    if (mainPromotions) {
      const chunkSize = 15
      for (let i = 0; i < mainPromotions.length; i += chunkSize) {
        promotionListEcomEvent({
          event: 'offer-allocation'
        }, mainPromotions.slice(i, i + chunkSize), i)
      }
    }
  }, [mainPromotions])

  useEffect(() => {
    const chunkSize = 15
    for (let i = 0; i < window.offersGroup.length; i += chunkSize) {
      promotionListEcomEvent({
        event: 'offer-group-allocation'
      }, window.offersGroup.slice(i, i + chunkSize), i)
    }
  }, [])

  const add_to_cart_button_click = useCallback(useDebounceCallback(() => {
    if (!ecom_button_disabled) {
      add_to_cart()
    }
  }), [ecom_button_disabled])

  const add_to_cart_button = useMemo(() => showEcommerceCartSuccess ? <></> : <div
    className={`primary_background text_primary_background common_text_button common_button_font_size common_button_border_radius common_button_shadow sendEcommerceCart_button ${!window.parameters.show_virtual_view_offers_as_carousel ? 'floating_button' : ''} ${ecom_button_disabled ? 'disabled' : ''}`}

    onClick={add_to_cart_button_click}
  >
    Adicionar item ao carrinho
  </div>, [
    ecom_button_disabled,
    add_to_cart_button_click,
    showEcommerceCartSuccess,
  ])

  const show_promotions_search = useMemo(() =>
    window.inIframe ?
      !window.parameters.disable_promotions_search_bar_vv
      && !window.parameters.show_virtual_view_offers_as_carousel
      :
      !window.parameters.disable_promotions_search_bar_pdc
    , [])

  const blur_search_bar = useCallback(
    useDebounceCallback(
      (e: Event & { target: HTMLInputElement }) => {
        const v = e.target.value
        if (v) {
          query.set(promotions_search_parameter, encodeURIComponent(v))
        } else {
          query.delete(promotions_search_parameter)
        }
        query.delete(promotions_scope_parameter)

        history.push({ pathname, search: query.toString() })
      }
      , 50)
    , [pathname, query])

  const keydown_search_bar = useCallback(
    useDebounceCallback(
      (e: KeyboardEvent) => {
        if (
          (
            [
              e.code,
              e.key,
            ].includes('Enter')
            || e.keyCode == 13
          )
        ) {
          (e.target as HTMLInputElement)?.blur()
        }
      }
      , 50)
    , [])

  const click_clear_filters = useCallback(
    useDebounceCallback(
      () => {
        query.delete(promotions_scope_parameter)
        query.delete(promotions_search_parameter)

        history.push({ pathname, search: query.toString() })

      }
      , 50)
    , [pathname, query])

  const click_clear_search = useCallback(
    useDebounceCallback(
      () => {
        if (query.get(promotions_search_parameter) || search_input) {
          query.delete(promotions_scope_parameter)
          query.delete(promotions_search_parameter)

          history.push({ pathname, search: query.toString() })
        }
      }
      , 50)
    , [
      pathname,
      query,
      search_input,
    ])

  const clear_filter_button = useMemo(() => <button onClick={click_clear_filters} className="clear_search_button text_primary_background primary_background ">
    Ver todas as ofertas
  </button>, [click_clear_filters])

  const empty_filtered_promotions = useMemo(() => active_filter ?
    <>
      <div className="empty_filtered_promotions wide">

        <LampImage className="image" />
        <span>
          No momento não há ofertas disponíveis para o filtro selecionado.
        </span>

        <span>
          Mas temos outras opções que você pode gostar! Tente um novo filtro ou veja todas as ofertas disponíveis para você!
        </span>

        {clear_filter_button}

      </div>
    </>
    :
    <div className="empty_filtered_promotions">
      Você pode verificar se há algum erro de digitação, utilizar menos palavras ou tentar uma palavra similar.

      {clear_filter_button}
    </div>, [
    active_filter,
    clear_filter_button,
  ])

  const promotions_header = useMemo(() =>
    <h3 className={`${!active_filter && search_promotions && !card_promotions.length ? 'center_text' : ''}`}>
      {
        active_filter
          ?
          <>
            <a className={'primary_color_text'} onClick={click_clear_filters} style={{
              cursor: 'pointer'
            }}>
              Ofertas
            </a>

            <Right_chevron_SVG className="chev_icon" />
            {active_filter.name}
          </>
          :
          search_promotions ?
            <>
              {
                card_promotions.length ?
                  `${card_promotions.length} oferta${card_promotions.length > 1 ? 's' : ''} encontrada na`
                  :
                  `Ops! Desculpe, não encontramos ofertas para a`
              }
              {` busca por "${search_promotions}"`}
            </>
            :
            'Ofertas que você pode gostar'
      }
    </h3>, [
    active_filter,
    search_promotions,
    card_promotions,
    click_clear_filters,
    search_promotions,

  ])

  return (
    <div className={`offers_page`}>

      {
        window.inIframe ?
          <div className="disclaimer primary_background text_primary_background">
            O desconto será aplicado ao finalizar sua compra, na etapa de pagamento.
          </div> : <></>
      }

      {
        window.inIframe
          && card_promotions?.length
          && !window.parameters.show_virtual_view_offers_as_carousel
          ?
          add_to_cart_button
          : <></>
      }

      <div className={`offers_scrollable_content ${window.inIframe ? 'inIframe' : ''}`}>

        {
          !window.parameters.disableStoreSelector
            && !showEcommerceCartSuccess
            ?
            <div className="preferred_store_line" onClick={(e) => {
              e.preventDefault()
              pdcStore.set.StoreSelectModalOpened(true)
            }}
            >
              {
                store_selected ?
                  <>
                    {"Sua loja favorita: "}
                    <span className="primary_color_text" style={{ fontWeight: 500 }}>
                      {store_selected?.businessName} ({store_selected?.city} - {store_selected?.state})
                    </span>
                  </>
                  :
                  <>
                    <span className="primary_color_text" style={{ fontWeight: 500 }}>
                      {"Selecione aqui "}</span>
                    sua loja favorita para ver suas ofertas
                  </>

              }
            </div>
            : ''
        }

        {
          show_promotions_search ?
            <div className="search_bar">
              <TextInput
                placeholder="Busque aqui sua oferta"
                inputClassName={`input-outlined`}
                //@ts-ignore
                autocomplete="off"
                ref={search_promotions_ref}

                value={search_input}
                onChange={(e) => {
                  set_search_input(e.target.value)
                }}

                onBlur={blur_search_bar}

                onKeyDown={keydown_search_bar}

                icon={
                  <>
                    <SearchSVG aria-label="search icon" className="search_icon" onClick={(e) => {
                      search_promotions_ref.current?.focus()
                    }} />
                    <CancelFilledSVG onClick={click_clear_search} onMouseDown={(e) => e.preventDefault()} aria-label="clear input" className="clear_search_icon" />
                  </>
                }
              />
            </div>
            : <></>
        }

        {
          window.inIframe ?
            <>
              {
                window.parameters.enable_virtual_view_banners
                  && !showEcommerceCartSuccess
                  ?
                  <TopCarousel />
                  : ''
              }

              {
                mainPromotions?.length ?
                  (
                    showEcommerceCartSuccess ?
                      <div className="addedToEcommerceCart">
                        <picture>
                          <CartSVG />
                        </picture>
                        <h5>Seus itens lhe aguardam em seu carrinho.</h5>

                        <h5>Boas compras!</h5>

                        <div
                          className={`primary_background text_primary_background common_text_button common_button_font_size common_button_border_radius common_button_shadow sendEcommerceCart_button `}

                          onClick={() => pdcStore.set.showEcommerceCartSuccess(false)}
                        >
                          Voltar
                        </div>
                      </div>
                      :
                      <>
                        {
                          window.parameters.show_virtual_view_offers_as_carousel ?
                            <>
                              <div className={`bottom-carousel-container inIframe`}>
                                <OffersCarousel
                                  list={mainPromotions}
                                />
                              </div>

                              {add_to_cart_button}
                            </>
                            :
                            <div className={`vertical_promotions_container inIframe`}>
                              <div className="vertical_promotions"
                                style={{
                                  padding: '24px 0',
                                }}>

                                {promotions_filters}

                                {
                                  loading_filtered_promotions ?
                                    <LoadingCircle className="loading_c" style={{
                                      'grid-column': '1 / -1'
                                    }} />
                                    :
                                    <>
                                      {promotions_header}

                                      {
                                        renderedMainPromotions?.length ?
                                          renderedMainPromotions?.map((item, i) =>
                                            <PromotionCard
                                              item={item}
                                              key={`vertical-${item.id}`}
                                              numberInList={i}
                                              observerRef={(e: HTMLDivElement) => {
                                                setMainCardsRefs(mainCardsRefs => [...mainCardsRefs, e])
                                              }}
                                            />
                                          )
                                          :
                                          empty_filtered_promotions
                                      }
                                    </>
                                }
                              </div>
                            </div>
                        }
                      </>
                  )
                  : (mainPromotions ? <NoOffers /> : <LoadingCircle />)
              }
            </>
            :

            mainPromotions ? (
              noPromotions ?
                <NoOffers />

                :

                <>

                  <TopCarousel list={bannerPromotions} />

                  {
                    window.offersGroup?.length
                      ?
                      <div className="bottom-carousel-container ">
                        <h3>
                          Ofertas em destaque
                        </h3>

                        <OffersCarousel
                          list={window.offersGroup}
                        />
                      </div>
                      : ''
                  }

                  <div className="vertical_promotions_container">

                    <div className="vertical_promotions">

                      {promotions_filters}

                      {
                        loading_filtered_promotions ?
                          <LoadingCircle className="loading_c" style={{
                            'grid-column': '1 / -1'
                          }} />
                          :
                          <>
                            {promotions_header}

                            {
                              renderedMainPromotions?.length ?
                                <>
                                  {
                                    renderedMainPromotions.slice(0, quantity_of_promotions_in_two_rows).map((item, i) =>
                                      <PromotionCard
                                        item={item}
                                        key={`vertical-${item.id}`}
                                        numberInList={i}
                                        observerRef={(e: HTMLDivElement) => {
                                          setMainCardsRefs(mainCardsRefs => [...mainCardsRefs, e])
                                        }}
                                      />
                                    )
                                  }
                                  {
                                    renderedMainPromotions.length > quantity_of_promotions_in_two_rows ?
                                      <>
                                        {
                                          window.parameters.glob_promotion_bar_app_cta
                                            && show_promotion_bar_app_cta ?
                                            <PromotionBarAppCTA />
                                            : <></>
                                        }
                                        {
                                          renderedMainPromotions.slice(quantity_of_promotions_in_two_rows).map((item, i) =>
                                            <PromotionCard
                                              item={item}
                                              key={`vertical-${item.id}`}
                                              numberInList={i + quantity_of_promotions_in_two_rows}
                                              observerRef={(e: HTMLDivElement) => {
                                                setMainCardsRefs(mainCardsRefs => [...mainCardsRefs, e])
                                              }}
                                            />
                                          )
                                        }
                                      </>
                                      : <></>
                                  }
                                </>
                                :
                                empty_filtered_promotions
                            }
                          </>
                      }
                    </div>
                  </div>

                </>)
              :
              <LoadingCircle />
        }
      </div>
    </div >
  )
})
