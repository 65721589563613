import {
  sessionParameter,
} from '@assets/utils'

import { getApiRoot } from '@assets/functions/getApiRoot'
import { Promotions_scopes } from '@assets/types/promotions_scopes'

import {
  V2_Promotions_Props,
  V2_Promotions_Query,
} from '@assets/types/v2_promotions'
import { parametersObjectToQueryString } from '@assets/functions'

export const v2_cxp_promotions_scopes = async (properties: V2_Promotions_Props): Promise<Promotions_scopes> => {

  const {
    mechanics,
    types,
    ...rest_properties
  } = properties

  const q: V2_Promotions_Query = rest_properties

  if (mechanics) {
    q.mechanics = mechanics.join(',')
  }

  if (types?.length && mechanics?.length) {
    q.types = types.join(',')

    const resp = await fetch(`${getApiRoot()}/v2/cxp/promotions/scopes?${parametersObjectToQueryString(q)}${sessionParameter()}`, {
      method: "GET",
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
    })

    if (resp.status == 200) {
      return resp.json()
    }
  }

  return []

}