import { createStore } from 'zustand-x'

//@ts-ignore
import clone from 'just-clone'

type LocalStore = {
  lastShowedSplashscreenAppCTA: number;
  lastShowedDeniedNotificationsModal: number;
  lastShowedFullsizeWebpushPopup: number;
  lastShowedWebpushBaloonPopup: number;
  lastShowedWebpushTopPopup: number;
  lastShowedWebpushCard: number;
  webPushDeviceId: string;
  
}

const initialState = {
  lastShowedSplashscreenAppCTA: 0,
  lastShowedDeniedNotificationsModal: 0,
  lastShowedFullsizeWebpushPopup: 0,
  lastShowedWebpushBaloonPopup: 0,
  lastShowedWebpushTopPopup: 0,
  lastShowedWebpushCard: 0,
  webPushDeviceId: '',
} as LocalStore

export const localStore = createStore('app')(initialState, {
  persist: {
    enabled: true,
    name: 'pdc-local-store',
  }
})
  .extendActions((set, get, api) => ({
    reset: () => {
      Object.getOwnPropertyNames(set)
        .filter(n => ![
          'mergeState',
          'state'
        ].includes(n))
        //@ts-ignore
        .forEach(n => set[n](initialState[n]))
    },
  }))