import { ReactComponent as Three_vert_dotsSVG } from '@assets/icons/three_vert_dots.svg'
import { ReactComponent as Gear_emptySVG } from '@assets/icons/gear_empty.svg'
import { ReactComponent as CursorSVG } from '@assets/icons/cursor.svg'
import { ReactComponent as Switch_offSVG } from '@assets/icons/switch_off.svg'
import { ReactComponent as Check_filledSVG } from '@assets/icons/check_in_filled_circle.svg'
import { ReactComponent as SlidersSVG } from '@assets/icons/sliders.svg'
import { ReactComponent as ListSVG } from '@assets/icons/list.svg'
import { ReactComponent as Gear_filledSVG } from '@assets/icons/gear_filled.svg'
import { ReactComponent as Person_emptySVG } from '@assets/icons/person_empty.svg'
import { ReactComponent as Bell_emptySVG } from '@assets/icons/bell_empty.svg'

import {
  semibold,
} from '@assets/constants/styles/classes'

const SwitchOff_with_check = <>
  <Switch_offSVG />
  <Check_filledSVG className="check_filled" />
</>

const start_text = <>
  Abra o
  <br />
  menu do
  <br />
  navegador
</>

export const chrome = {
  name: "Google Chrome",
  steps: [
    {
      icon: <Three_vert_dotsSVG />,
      text: start_text,
    },
    {
      icon: <SlidersSVG />,
      text: <>
        Clique
        <br />
        na opção
        <br />
        <span className={semibold}>Permissões</span>
      </>
    },
    {
      icon: SwitchOff_with_check,
      text: <>
        Selecione
        <br />
        <span className={semibold}>Notificações</span>
        <br />
        e ative-as!
      </>
    },
  ]
}

export const samsung = {
  name: "Samsung Internet", steps: [
    {
      icon: <ListSVG />,
      text: start_text,
    },
    {
      icon: <CursorSVG />,
      text: <>
        Clique na
        <br />
        {'opção '}
        <span className={semibold}>
          Sites
          <br />
          e Downloads
        </span>
      </>
    },
    {
      icon: <Three_vert_dotsSVG />,
      text: <>
        Selecione
        <br />
        <span className={semibold}>Notificações</span>
        <br />
        e clique nos
        <br />
        3 pontinhos
      </>
    },
    {
      icon: SwitchOff_with_check,
      text: <>
        {'Vá em '}
        <span className={semibold}>Permitir
          <br />
          ou Bloquear
          <br />
          Sites</span> e ative as
        <br />
        notificações
      </>
    },
  ]
}

export const firefox = {
  name: 'Firefox',
  steps: [
    {
      icon: <Three_vert_dotsSVG />,
      text: start_text,
    },
    {
      icon: <Gear_emptySVG />,
      text: <>
        Clique na
        <br />
        opção
        <br />
        <span className={semibold}>
          Configurações
        </span>
      </>
    },
    {
      icon: <CursorSVG />,
      text: <>
        Selecione
        <br />
        <span className={semibold}>
          Permissões
          <br />
          de Sites
        </span>
      </>
    },
    {
      icon: SwitchOff_with_check,
      text: <>
        Clique em
        <br />
        <span className={semibold}>
          Notificações</span> e
        <br />
        ative-as!
      </>
    },
  ]
};

export const edge = {
  name: "Microsoft Edge",
  steps: [
    {
      icon: <ListSVG />,
      text: start_text,
    },
    {
      icon: <Gear_filledSVG />,
      text: <>
        Clique
        <br />
        na opção
        <br />
        <span className={semibold}>Configurações</span>
      </>
    },
    {
      icon: SwitchOff_with_check,
      text: <>
        Selecione
        <br />
        <span className={semibold}>Notificações</span>
        <br />
        e ative-as!
      </>
    },
  ]
}

export const opera = {
  name: "Opera",
  steps: [
    {
      icon: <Person_emptySVG />,
      text: start_text,
    },
    {
      icon: <Gear_emptySVG />,
      text: <>
        Clique na
        <br />
        opção
        <br />
        <span className={semibold}>
          Configurações
        </span>
      </>
    },
    {
      icon: <Bell_emptySVG />,
      text: <>
        Selecione a
        <br />
        opção
        <br />
        <span className={semibold}>Notificações</span>
      </>
    },
    {
      icon: SwitchOff_with_check,
      text: <>
        Clique em
        <br />
        <span className={semibold}>
          Notificações
          <br />
          push
        </span> e
        <br />
        ative-as!
      </>
    },
  ]
}