import { css } from "@linaria/core";

export const text_nowrap = css`
  text-wrap: nowrap;
`

export const semibold = css`
  font-weight: 600;
`

export const light = css`
  font-weight: 300;
`

export const cursor_pointer = css`
  cursor: pointer;
`

export const cursor_default = css`
  cursor: default;
`

export const hover_darken = css`
  &:hover{
    filter: brightness(.95);
  }
`

export const color_primary = css`
  color: var(--color-primary)
`