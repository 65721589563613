export const filters = [
  {
    name: 'Novidades',
    filter: 'recent',
  },
  {
    name: 'Expiram em breve!',
    filter: 'expiring',
  },
  {
    name: 'Exclusivas para você',
    filter: 'exclusive',
  },
  {
    name: 'Porque você acessou o App',
    filter: 'activation',
  },
  {
    name: 'Exclusivas do clube',
    filter: 'identification',
  },
  {
    name: 'Para quem completou o cadastro',
    filter: 'registration',
  },
  {
    name: 'Cashback',
    filter: 'cashback',
  },
  {
    name: 'Leve + Pague -',
    filter: 'bargain',
  },
] as const