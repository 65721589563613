import { Styled_scale_props_sizes } from '@assets/types/Styled_scale_props'

export const sizes = {
  close_button_size: [17.45, 12.41],
  close_button_top: [56.52, 24],

  main_icon_height: [193.55, 118.37],
  main_icon_width: [265.29, 162.24],
  main_icon_margin_top: [44.82, 24],

  header_font_size: [32, 12],
  header_line_height: [45, 17],
  header_margin_top: [16, 24],
  header_margin_top_headless: [, 44],
  header_margin_bottom: [8, 8],

  text_font_size: [20, 10],
  text_line_height: [28, 15.5],
  text_margin_bottom: [32, 24],

  main_browsers_margin_bottom: [32, 16],

  secondary_browsers_margin_bottom: [34.38, 8],
  carousel_container_p: [63.1, null],

  steps_description_width: [578.39, 350],
  steps_description_height: [257.8, 144],
  steps_description_height_big: [257.8, 156],
  steps_description_margin_left_main: [40, null],
  steps_description_margin_left: [16, null],
  steps_description_margin_bottom: [, 8],

  steps_description_name_font_size: [19, 12],
  steps_description_name_line_height: [27, 17],
  steps_description_name_margin_top: [13.3, 8],
  steps_description_name_margin_left: [26.44, 16],
  steps_description_name_margin_bottom: [27.53, 16],

  steps_background_position: [33.05, 20],

  step_width: [66.1, 40],

  step_icon_margin_bottom: [13.22, 8],

  step_icon_three_dots_width: [29.78, null],

  check_filled_size: [39.66, 24],
  check_filled_bottom: [-11, -6.65],

  step_text_font_size: [15, 10],
  step_text_line_height: [19, 13],

  bottom_text_fs: [20, 10],
  bottom_text_lh: [28, 15],
  bottom_text_mb: [45.82, 32.03],

  popup_back_button_mb: [45.82, 21],

  arrow_w: [15.14, null],
  arrow_h: [26.91, null],
} as Styled_scale_props_sizes