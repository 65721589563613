import React, { useMemo, FC, useState, useEffect, } from 'react'

import {
  useLocation,
  useHistory
} from "react-router-dom"

import { Helmet } from "react-helmet"

/* Form components */
import { useFormik } from 'formik'
import * as yup from 'yup'

import {
  TextInput,
  Button,
  Checkbox,
} from "react-materialize"

import { DisableOverflowScrolling } from '@assets/components/DisableOverflowScrolling'

import { TermsDocuments } from '@assets/components/TermsDocuments'

import { useMediaQuery } from 'react-responsive'

/* Data */
import { useAppDispatch,  } from "@/app/hooks"
import {
  deleteCustomerProps,
  updateCustomer,
} from "@/features/propzMedia/propzMediaSlice"

import {
  getNextRegStep,
} from '@/utils/internal functions'

import {
  Customer,
} from '@assets/types'

import {
  actions,
  useTrackedStore,
  store,
} from '@assets/store'

export const NameForm: FC = () => {

  const history = useHistory()

  const dispatch = useAppDispatch()

  const {
    search,
    pathname,
  } = useLocation()

  const isNotMobile = useMediaQuery({ query: '(min-width: 768.1px)' }) ? 'input-outlined' : ''

  const customer = useTrackedStore().app.customer()

  const [rulesCheckbox, setRulesCheckbox] = useState(localStorage.getItem('lgpdCheckboxChecked') == 'true')

  let validationSchema = useMemo(() => yup.object({
    /* firstName: yup
      .string()
      .min(3, 'não deve ser inferior a 3')
      .required(window.userIsFisicalPerson ? 'O nome é obrigatório.' : 'Razão social é obrigatório '), */
  }), [])

  const formik = useFormik({
    initialValues: {
      firstName: customer?.firstName || '',
      lastName: customer?.lastName || ''
    },
    validationSchema: validationSchema,
    onSubmit: async (value) => {
      //console.log('formik submit : ', value);

      document.body.style.cursor = 'wait';

      localStorage.setItem('lgpdCheckboxChecked', 'true')

      let props: Customer = {
        registeredInPartnerProgram: true,
      }

      if (window.userIsFisicalPerson) {
        if (value.lastName) {
          props.lastName = value.lastName
        } else {
          if (customer.lastName) {
            await dispatch(deleteCustomerProps(['lastName']))
          }
        }
      }

      if (value.firstName) {
        props.firstName = value.firstName
      } else {
        if (customer.firstName) {
          await dispatch(deleteCustomerProps(['firstName']))
        }
      }

      dispatch(updateCustomer(props))
        .finally(() => {
          document.body.style.cursor = 'default'
          formik.setSubmitting(false);
          history.push(`/cadastro-${getNextRegStep(pathname)}${search}`)
        });
    },
  })

  const [firstNameInputNode, setFirstNameInputNode] = useState<HTMLInputElement | null>()

  useEffect(() => {
    if (firstNameInputNode) {
      //@ts-ignore
      firstNameInputNode.addEventListener('input', function (e: InputEvent) {

        //console.log((e?.target as HTMLInputElement)?.value)

        formik.setFieldValue("firstName", (e?.target as HTMLInputElement)?.value, false)
      })
    }
  }, [firstNameInputNode])

  const [lastNameInputNode, setLastNameInputNode] = useState<HTMLInputElement | null>()

  useEffect(() => {
    if (lastNameInputNode) {
      //@ts-ignore
      lastNameInputNode.addEventListener('input', function (e: InputEvent) {

        //console.log((e?.target as HTMLInputElement)?.value)

        formik.setFieldValue("lastName", (e?.target as HTMLInputElement)?.value, false)
      })
    }
  }, [lastNameInputNode])

  return (

    <form onSubmit={formik.handleSubmit} className='roll_in_from_bottom_animation full_page_form'>
      <Helmet>
        <title>Informe seu nome</title>
        <meta name="description" content={`Solicitação do ${window.userIsFisicalPerson ? 'nome' : 'razão social'}.`} />
      </Helmet>
      {/* firstName */}
      <TextInput
        id="firstName"
        name="firstName"
        label={`${window.userIsFisicalPerson ? 'Primeiro nome' : 'Razão social'}`}
        //@ts-ignore
        className={`${isNotMobile} ${formik.touched.firstName && Boolean(formik.errors.firstName) && 'invalid' || ''}`}
        value={formik.values.firstName}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        error={formik.touched.firstName && formik.errors.firstName || ' '}

        disabled={formik.isSubmitting}

        //@ts-ignore
        ref={e => setFirstNameInputNode(e)}
      />

      {/* lastName */}
      {
        window.userIsFisicalPerson ?
          <TextInput
            id="lastName"
            name="lastName"
            label="Sobrenome"
            //@ts-ignore
            className={`${isNotMobile}`}
            value={formik.values.lastName}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}

            disabled={formik.isSubmitting}

            //@ts-ignore
            ref={e => setLastNameInputNode(e)}
          />
          : ''
      }

      <div className="info" style={{
        textAlign: window.parameters.glob_LGPDFlow ? 'start' : 'center'
      }}>
        {
          window.parameters.glob_LGPDFlow ?
            <Checkbox
              className="rounded_checkbox"
              checked={rulesCheckbox}
              filledIn
              label=""
              //@ts-ignore
              value={true}
              //@ts-ignore
              onChange={e => setRulesCheckbox(e.target.checked)}
            />
            : ''
        }

        <TermsDocuments />
      </div>

      {/* Submit */}
      <Button
        flat
        //@ts-ignore
        type="submit"
        disabled={
          formik.isSubmitting
          || !formik.isValid
          || (
            window.parameters.glob_LGPDFlow && !rulesCheckbox
          )
        }
        waves="light" className="white-text"
      >
        <DisableOverflowScrolling />
        Continuar
      </Button>

    </form>
  )
}