import {
  regStepEnabled,
  notificationsNotAvailable,
} from "@/utils/internal functions"

import {
  actions,
  useTrackedStore,
  store,
} from '@assets/store'

export const getRegPhases = () => {

  const customer = store.app.customer()

  return [
    'nome',
    'email',
    'celular',
    
    ...notificationsNotAvailable() ? [] : ['aceite-webpush'],

    ...regStepEnabled('category'),
    ...regStepEnabled('profession'),
    ...regStepEnabled('endereco'),
    ...(
      //additional forms for fisical person
      window.userIsFisicalPerson ? [
        ...regStepEnabled('data-nascimento'),
        ...regStepEnabled('genero')
      ] : []
    ),
    ...(
      //final step depends on registration completion
      customer?.isRegisterComplete ? ['completo'] : ['atualizado']
    )
  ]
}