import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import { desktop, mobile } from '@assets/constants/styles/media'
import { rwsize } from '@assets/functions/rwsize'

import { Styled_scale_props } from '@assets/types/Styled_scale_props'
import { scaled } from '@assets/functions/scaled'
import {
  text,
  background,
  text_shadow,
} from '@assets/constants/styles/colors'

export const Content = styled.div<Styled_scale_props>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  position: relative;
  flex-grow: 1;
  background-color: #fff;

  .close_button{
    position: absolute;
    height: ${scaled('close_button_size')};
    width: ${scaled('close_button_size')};
    top: ${scaled('close_button_top')};
    right: ${scaled('close_button_top')};
  }

  .main_icon{
    height: ${scaled('main_icon_height')};
    width: ${scaled('main_icon_width')};
    margin-top: ${scaled('main_icon_margin_top')};

  }

  .header{
    font-weight: 700;
    color: ${text};
    margin: 0;
    text-align: center;
    text-shadow: 0 1px 0 ${text_shadow};
    
    font-size: ${scaled('header_font_size')};
    line-height: ${scaled('header_line_height')};
    margin-top: ${scaled('header_margin_top')};
    margin-bottom: ${scaled('header_margin_bottom')};

    &.headless{
      margin-top: ${scaled('header_margin_top_headless')};
    }
  }

  .text{
    color: ${text};
    text-align: center;

    font-size: ${scaled('text_font_size')};
    line-height: ${scaled('text_line_height')};
    margin-bottom: ${scaled('text_margin_bottom')};
  }

  .main_browsers, .secondary_browsers, .carousel_container{
    display: flex;

    ${desktop}{
      flex-direction: row;
    }

    ${mobile}{
      flex-direction: column;
    }
  }

  .main_browsers, .carousel_container{
    margin-bottom: ${scaled('main_browsers_margin_bottom')};
  }

  .main_browsers{
    .steps_description{
      margin-left: ${scaled('steps_description_margin_left_main')};
    }
  }

  .carousel_container{
    ${desktop}{
      position: relative;
      width: 100%;
      padding-left: ${scaled('carousel_container_p')};
      padding-right: ${scaled('carousel_container_p')};
    }

    .secondary_browsers{
      flex-shrink: 0;

      ${desktop}{
        overflow-x: scroll;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none;
        width: 100%;
        
        &::-webkit-scrollbar{
          display: none;
        }
      }
    }

    .left_arrow, .right_arrow{
      width: ${scaled('carousel_container_p')};
      position: absolute;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: #fff;

      svg{
        width: ${scaled('arrow_w')};
        height: ${scaled('arrow_h')};
      }
    }

    .left_arrow{
      left: 0;
    }

    .right_arrow{
      right: 0;
    }
  }

  .steps_description{
    background-color: ${background};
    color: ${text};
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: ${scaled('steps_description_width')};
    height: ${scaled('steps_description_height')};
    margin-left: ${scaled('steps_description_margin_left')};
    margin-bottom: ${scaled('steps_description_margin_bottom')};

    &:first-child{
      margin-left: 0;
    }

    &.big{
      height: ${scaled('steps_description_height_big')};
    }

    ${desktop}{
      border-radius: 16px
    }

    ${mobile}{
      border-radius: 8px
    }

    .steps_description_name{
      align-self: flex-start;

      font-size: ${scaled('steps_description_name_font_size')};
      line-height: ${scaled('steps_description_name_line_height')};
      margin-top: ${scaled('steps_description_name_margin_top')};
      margin-left: ${scaled('steps_description_name_margin_left')};
      margin-bottom: ${scaled('steps_description_name_margin_bottom')};
    }

    .steps{
      display: flex;
      flex-direction: row;
      align-self: center;
      background: repeating-linear-gradient(to right, #6E6E6E, #6E6E6E 2px, transparent 2px, transparent 4px);
      background-size: calc(100% - ${scaled('step_width')}) 2px;
      background-repeat: no-repeat;

      background-position: ${scaled('steps_background_position')} ${scaled('steps_background_position')};

      .step{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: ${scaled('step_width')};
        &:not(:last-of-type){
          margin-right: ${scaled('step_width')};
        }

        .step_icon{
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          border: 2px solid #707070;
          background: ${background};
          width: ${scaled('step_width')};
          height: ${scaled('step_width')};
          margin-bottom: ${scaled('step_icon_margin_bottom')};

          .check_filled {
            position: absolute;
            width: ${scaled('check_filled_size')};
            height: ${scaled('check_filled_size')};
            bottom: ${scaled('check_filled_bottom')};
            right: calc(${scaled('check_filled_size')}/-2);
          }

          svg:first-child{
            height: 50%;
            width: 50%;
          }
        }

        .step_text{
          text-align: center;
          font-size:${scaled('step_text_font_size')};
          line-height:${scaled('step_text_line_height')};
          display: inline-block;
        }
      }
    }
  }

  .bottom_text{
    font-size: ${scaled('bottom_text_fs')};
    line-height: ${scaled('bottom_text_lh')};
    margin-bottom: ${scaled('bottom_text_mb')};

  }

  .popup_back_button{
    font-size: ${scaled('bottom_text_fs')};
    line-height: ${scaled('bottom_text_lh')};
    margin-bottom: ${scaled('popup_back_button_mb')};
  }
`

export const DeniedNotificationsModal_css = css`
  &.modal{
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    overflow: hidden;
    padding: 0;

    &.open {
      display: flex!important;
    }

    ${desktop}{
      border-radius: 56px;
      max-width: 1473px;
      width: calc(100% - ${rwsize(24)});
      top: 10vh!important;
      max-height: max(80vh, 731px);
    }

    ${mobile}{
      border-radius: 22px;
      top: 0!important;
      bottom: 0;
      width: calc(100% - ${rwsize(24)});
      margin: auto;
      height: fit-content;
      max-height: calc(100% - ${rwsize(38)});
    }

    .modal-content{
      display: flex;
      flex-direction: column;
      justify-content: stretch;
      align-items: stretch;
      padding: 0;
      flex-grow: 1;

      h4{
        display: none
      }
    }
    
    .modal-footer{
      display: none;
    }
  }
`