import { FC, useEffect, useMemo, useCallback } from "react"

import { Modal, Button } from "react-materialize"

import { format } from 'date-fns'

import { isTicketPromotion } from '@assets/functions/isTicketPromotion'

import { isCommonPromotion } from '@assets/functions/isCommonPromotion'

import { promotionEcomEvent } from "@assets/utils/promotionEcomEvent"

//@ts-ignore
import StringMask from 'string-mask'

import './style.sass'

import {
  actions,
  useTrackedStore,
  useStore,
} from '@assets/store'

import { PromotionInfoForPopup } from '@assets/components/PromotionInfoForPopup'

import {
  parseDate,
} from '@assets/utils'
import { useDebounceCallback } from "usehooks-ts"

export const OfferConditionsModal: FC = () => {

  const opened = useTrackedStore().app.OfferConditionsModalOpened()

  const promotion = useTrackedStore().app.OfferConditionsModalItem()

  const customer = useStore().app.customer()

  const closePopup = useCallback(() => actions.app.OfferConditionsModalOpened(false), [])

  const isTicket = useMemo(() => isTicketPromotion(promotion?.mechanic ?? ''), [promotion])

  const isCommon = useMemo(() => isCommonPromotion(promotion?.mechanic ?? ''), [promotion])

  useEffect(() => {
    if (opened && promotion) {
      promotionEcomEvent({
        event: 'offer_condition_modal_view',
      }, promotion)
    }
  }, [
    opened,
    promotion,
  ])

  const stores_link_click = useCallback(useDebounceCallback((e) => {

    actions.app.CurrentOfferStoresModalPromotion(promotion)

    actions.app.currentOfferStoresModalOpened(true)

    promotionEcomEvent({
      event: 'offer_condition_view_stores_link_click',
    }, promotion)

  }, 100), [promotion])

  const anon_customerid = useMemo(() => StringMask.apply(customer?.customerId?.slice(-3), '0-00'), [customer.customerId])

  const personalized_message = useMemo(() => `Oferta exclusiva para este usuário mediante identificação com o CPF ***.***.** ${anon_customerid} `, [anon_customerid])

  return (
    <Modal
      actions={[]}
      bottomSheet
      id="offer_conditions"
      open={opened}
      options={{
        dismissible: true,
        endingTop: "50%",
        inDuration: 250,
        opacity: 0.5,
        outDuration: 250,
        preventScrolling: true,
        startingTop: "4%",
        onCloseEnd: closePopup,
      }}
    >
      <PromotionInfoForPopup promotion={promotion} close={closePopup} />

      <span className="header">Condições da oferta</span>

      {
        [
          'cashback_product_money',
          'cashback_product_percent',
        ].includes(promotion?.mechanic ?? '') ?
          <>
            <span className="line">
              O cashback será calculado com base no valor de cada unidade adquirida do produto. O valor será aprovado e estará disponível para sua próxima compra em até 7 dias.
            </span>
            <span className="line">
              Uma vez recebido, o cashback ficará disponível para uso por 30 dias.
            </span>
          </>
          : <></>
      }

      {
        isCommon
          && ((Number(promotion?.minQuantity) ?? 0) > 1) ?
          <span className="line">
            {`Quantidade mínima para garantir seu desconto: `} {promotion?.minQuantity}
          </span>
          : <></>
      }

      {
        ['cashback_money', 'cashback_percent'].includes(promotion?.mechanic ?? '') ?
          <span className="line">
            {`O cashback será aprovado e estará disponível para sua próxima compra em até ${window.settings["retail.pos.cashback.approval.days"] || 7} dias.`}
          </span>
          :
          ['cashback', 'spend_and_get', 'spend_and_get_gift', 'spend_and_get_percent'].includes(promotion?.mechanic ?? '') ?
            <span className="line">
              O valor do desconto será aplicado diretamente na sua compra.
            </span>
            :
            (
              !!promotion?.legal
              || !!promotion?.propertiesMap?.legalText
            ) ?
              <span className="line">
                {promotion?.legal
                  || promotion?.propertiesMap?.legalText}
              </span>
              : <></>
      }

      {
        ['cashback_money', 'cashback_percent'].includes(promotion?.mechanic ?? '') ?
          <span className="line">
            Após receber o cashback, ficará disponível para uso por 30 dias.
          </span>
          : ''
      }

      {/* Max per customer */}
      {
        (
          promotion?.maxPerCustomer
          && !(promotion?.maxPerCustomer < 1)
        )
          || (
            promotion?.propertiesMap?.maxPerCustomer
            && !(promotion?.propertiesMap?.maxPerCustomer < 1)
          )
          ?
          <span className="line">
            {
              isTicket ?
                <>
                  {'Máximo de utilizações por CPF: '}{promotion?.remaining || promotion?.propertiesMap?.remaining} {' Vez'}
                </>
                :
                <>
                  {'Máximo de unidades por consumidor: '}{promotion?.remaining || promotion?.propertiesMap?.remaining}/{promotion?.maxPerCustomer || promotion?.propertiesMap?.maxPerCustomer}
                </>
            }
          </span>
          : ''
      }

      {
        promotion?.paymentMethod &&
          promotion?.paymentMethod == window.parameters.glob_cardPaymentId
          ?
          <span className="line">Oferta válida somente para compras no cartão da loja</span>
          : ''
      }

      {/* End date */}
      <span className="line">
        <i className="material-icons">schedule</i>
        {'Válido até '}
        {
          promotion?.endDate ?
            format(parseDate(promotion?.endDate) as Date, 'dd/MM/yyyy')
            :
            promotion?.propertiesMap?.endDate || ''
        }
      </span>

      {
        promotion?.storeIds?.length
          ?
          <Button
            flat
            waves="cyan"
            className="white stores-button"

            onClick={stores_link_click}
          >
            <i className="material-icons">location_on</i>
            Ver lojas participantes
          </Button>
          : <></>
      }

      <span className="line">
        {
          promotion?.type == "PERSONALIZED" ?
            promotion?.storeIds?.length ?
              `${personalized_message}no caixa das lojas participantes.`
              :
              `${personalized_message}em todas as lojas.`
            :
            promotion?.requiresActivation || promotion?.requiresIdentification || promotion?.requiresRegisterComplete ?
              `Oferta válida para clientes ${window.parameters.glob_programName} mediante identificação com o CPF ***.***.** ${anon_customerid} no caixa das lojas participantes.`
              :
              `Oferta válida para clientes ${window.parameters.glob_programName} apenas nas lojas participantes.`
        }
      </span>

      {
        isCommon ?
          <span className="line">
            Oferta válida enquanto durarem os estoques.
          </span>
          : <></>
      }

    </Modal>
  );
};