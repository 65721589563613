import { FC, useMemo, useState, useEffect, useCallback } from "react"
import { useLocation } from "react-router-dom"

import { useAppDispatch, useAppSelector } from "@/app/hooks"
import {
  updateCustomer
} from "@/features/propzMedia/propzMediaSlice"

import './style.sass'

import { orderedPopupTimings } from '@assets/constants/orderedPopupTimings'

import {
  TopSlidingPopup
} from '@/parts'

import bellSVG from '@/assets/icons/bell.svg'

import {
  setPropzMediaStateParameter,
} from "@/features/propzMedia/propzMediaSlice"

import {
  notificationsNotAvailable,
  showed_later_days_diff,
} from '@/utils/internal functions'

import { pdcStore } from "@assets/store/pdcStore"

import { useApp } from "@assets/hooks/useApp"
import { localStore } from "@/store/localStore"

export const WebpushTopPopup: FC = () => {

  const { pathname } = useLocation()

  const dispatch = useAppDispatch()

  const {
    showWebpushTopPopup,
  } = useAppSelector(
    (state) => state.propzMedia
  )

  const TopBarAppCTAOpened = pdcStore.use.TopBarAppCTAOpened()

  const isMobile = 'ontouchstart' in window

  //used to disable popup flickering
  const [popupOpen, setPopupOpen] = useState(false)

  const [show_pwa_ctas] = useApp()

  useEffect(() => {
    if (
      showWebpushTopPopup
    ) {

      const lastShowedWebpushTopPopup = showed_later_days_diff(localStore.get.lastShowedWebpushTopPopup(), window.parameters.header_webpush_period)

      if (
        !show_pwa_ctas
        //window.rawUser.webPushContactFlag is a string "0" || "1", so it's converted to number to properly convert to boolean
        && !Number(window.rawUser.webPushContactFlag)
        && !window.parameters.webpush_top_popup_disabled
        && !notificationsNotAvailable()
        && lastShowedWebpushTopPopup
        && !TopBarAppCTAOpened
      ) {

        setPopupOpen(true)
        pdcStore.set.increment_showed_popups_count()

        localStore.set.lastShowedWebpushTopPopup(new Date().getTime())

      } else {
        closePopup()
      }
    }
  }, [
    showWebpushTopPopup,
  ])

  const action = useCallback(() => {

    dispatch(updateCustomer({ webPushContactFlag: true }))

    pdcStore.set.checkNotificationsPermission('push_notification_headerbar_click')

    closePopup(true)

  }, [])

  const opened = useMemo(() =>
    (
      !pathname.includes('cadastro')
      && !pathname.includes('webpush-pagina')
      && showWebpushTopPopup
      && popupOpen
    ) ? 'opened' : ''
    , [pathname, showWebpushTopPopup, popupOpen])

  useEffect(() => {
    if (opened) {
      window.dataLayer.push({
        event: "push_notification_headerbar_view",
      })
    }
  }, [opened])

  const closePopup = useCallback((wait?: boolean) => {
    dispatch(setPropzMediaStateParameter({ showWebpushTopPopup: false }))

    setTimeout(() => {
      if (pdcStore.get.showed_popups_count() < window.parameters.allowed_popus_count) {
        //if can show one more -> switch last closed popup
        pdcStore.set.lastClosedPopup('ContinueRegistrationModal')
      }
    }, wait ? orderedPopupTimings.ContinueRegistrationModal : 0)
  }, [])

  return (
    <TopSlidingPopup
      image={bellSVG}
      className={`WebpushTopPopup`}
      header="Quer receber ofertas exclusivas para você? Ative as notificações e não perca estas vantagens!"
      mobile_header="Quer receber ofertas exclusivas para você?"
      subheader={isMobile ? "Ative as notificações e não perca estas vantagens!" : ''}
      button_text="Ative agora mesmo!"
      button_mobile_text="Ative agora mesmo!"
      button_action={action}
      opened={opened}
      close={() => {
        closePopup(true)
        //denyNotifications()
      }}
    />
  );
};