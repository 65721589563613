import { isApp } from "@assets/utils/isApp"
import { useState } from "react"

/* returns true if application is used outside the browser */
export function useIsApp(): boolean {

  const [is_app, set_is_app] = useState(isApp())

  const event_listener = window.matchMedia('(display-mode: standalone)')?.addEventListener

  if (event_listener && typeof event_listener === 'function') {
    window.matchMedia('(display-mode: standalone)')?.addEventListener("change", e => {
      set_is_app(e.matches)
    })
  }

  return is_app || Boolean(('standalone' in navigator && navigator.standalone) || document.referrer?.startsWith('android-app://'))
}