import React, { FC, useEffect, useState, useRef, } from 'react'

import { useHistory, useLocation } from "react-router-dom"

import { Helmet } from "react-helmet"

//@ts-ignore
import StringMask from 'string-mask'
import InputMask from "react-input-mask"

/* Form components */
import { useFormik } from 'formik'
import * as yup from 'yup'

import { Button, Checkbox, Modal, Select, TextInput } from "react-materialize"

/* Data */
import { useAppDispatch } from "@/app/hooks"
import {
  deleteCustomerProps,
  requestDeleteData,
  updateCustomer,
  setPropzMediaStateParameter,
} from "@/features/propzMedia/propzMediaSlice"

import { ReactComponent as CheckSVG } from '../check.svg'
import { ReactComponent as CheckFilledSVG } from '../check_filled.svg'

import { TextInputWithSearchDropdown } from '@/parts'

import { regStepEnabled, remove_select_touch_end, } from '@/utils/internal functions'

import { parseDate } from '@assets/utils'

/* Date picker  */
import DatePicker from "react-datepicker"
import ptBR from "date-fns/locale/pt-BR"
import { compareAsc, formatISO } from 'date-fns'

import { useMediaQuery } from 'react-responsive'

import '@/pages/UserInfo/modal.sass'

import { Customer, DeletableProps, } from '@assets/types'

import { DisableOverflowScrolling } from '@assets/components/DisableOverflowScrolling'

import { useTrackedStore, } from '@assets/store'
import { pdcStore } from '@assets/store/pdcStore'

let eighteenYearsAgo = new Date()
eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18)

export const Edit: FC = () => {

  const missingInfo = <span className='missing_info'>Informação pendente</span>

  const { search } = useLocation()

  const history = useHistory()

  const dispatch = useAppDispatch()

  const isNotMobile = useMediaQuery({ query: '(min-width: 767.9px)' }) ? 'input-outlined' : ''

  const pickerRef = useRef<DatePicker>()

  const [cepPrev, setCepPrev] = useState<string | undefined>()
  const [cepPrevValid, setCepPrevValid] = useState(true)

  const [openDeleteModal, setOpenDeleteModal] = useState(false)

  const customer = useTrackedStore().app.customer()

  /* Form settings */
  let validationSchema = yup.object({
    firstName: yup
      .string(),
    //.min(3, 'não deve ser inferior a 3')
    //.required('O nome é obrigatório.'),
    mobilePhone: yup
      .string()
      .required('Insira um número de celular válido')
      .test({
        message: 'Insira um número de celular válido',
        test: value => {
          if (value && value?.length) {
            let phone = value.replace(/\D/g, '')
            if (phone?.length != 11 || ["00000000000", "11111111111", "22222222222", "33333333333", "44444444444", "55555555555", "66666666666", "77777777777", "88888888888", "99999999999"].includes(phone)) return false;
            return true
          } else return false
        }
      }),
    homeEmail: yup
      .string()
      .email("Insira um endereço de e-mail válido")
      .required("Insira um endereço de e-mail válido"),
    ...regStepEnabled('endereco').length ?
      {
        //@ts-ignore
        homeZip: yup
          .string()
          //.required('Insira um CEP válido')
          .test({
            message: 'Insira um CEP válido',
            test: value => {
              //console.log('homeZip : ', value, cepPrev, cepPrevValid)
              if (!value) {
                formik?.setFieldValue("homeStreet", '', false);
                formik?.setFieldValue("homeDistrict", '', false);
                formik?.setFieldValue("homeCity", '', false);
                formik?.setFieldValue("homeState", '', false);
                formik?.setFieldValue("homeNum", '', false);
                formik?.setFieldValue("homeComplement", '', false);
              }

              if (cepPrev != value) {
                setCepPrev(value);
                if (value && value?.length) {
                  let homeZip = value.replace(/\D/g, '')
                  if (homeZip?.length == 8 && /^[0-9]{8}$/.test(homeZip)) {
                    return fetch(`https://viacep.com.br/ws/${homeZip}/json/`)
                      .then(res => res.json())
                      .then(dados => {
                        //console.log(dados);
                        if (!("erro" in dados)) {

                          //console.log(formik)

                          formik?.setFieldValue("homeStreet", dados.logradouro, false);
                          formik?.setFieldValue("homeDistrict", dados.bairro, false);
                          formik?.setFieldValue("homeCity", dados.localidade, false);
                          formik?.setFieldValue("homeState", dados.uf, false);

                          setCepPrevValid(true);

                          return true;
                        } else {
                          setCepPrevValid(false)
                          return false
                        }
                      })
                  } else {
                    setCepPrevValid(false)
                    return false
                  }
                }
                else {
                  setCepPrevValid(true)
                  return true
                }
              } else return cepPrevValid
            }
          })
      } : {},
    /*  homeStreet: yup
       .string()
       .required('O logradouro é obrigatório'),
     homeNum: yup
       .string()
       .required('O número é obrigatório'),
     homeDistrict: yup
       .string()
       .required('O bairro é obrigatório'),
     homeCity: yup
       .string()
       .required('A cidade é obrigatória'),
     homeState: yup
       .string()
       .required('O estado é obrigatório'), */
    ...window.userIsFisicalPerson ? {
      ...regStepEnabled('data-nascimento').length ?
        {
          //@ts-ignore
          date: yup
            .string()
            .required("Insira uma data de nascimento válida")
            .typeError("Insira uma data de nascimento válida")
            //@ts-ignore
            .test({
              message: "Insira uma data de nascimento válida",
              test: v => {
                //@ts-ignore
                return !!v && v != 0
              }
            })
            .test({
              message: "Você deve ser maior de 18 anos para se cadastrar",
              test: v => {
                return compareAsc(new Date(v), eighteenYearsAgo) != 1
              }
            }),
        } : {},
      /* ...regStepEnabled('genero').length ?
        {
          gender: yup
            .string()
            //.min(1, 'Selecione uma opção para prosseguir')
            //.required('Selecione uma opção para prosseguir'),
        } : {} */
    } : {}
  })

  const formik = useFormik({
    initialValues: {
      firstName: customer?.firstName ?? '',
      mobilePhone: customer?.mobilePhone?.replace('+55', '')?.replace(/\D/g, '') ?? '',
      homeEmail: customer?.homeEmail ?? '',
      ...regStepEnabled('endereco').length ?
        {
          homeZip: customer?.homeZip ?? '',
          homeStreet: customer?.homeStreet ?? '',
          homeNum: customer?.homeNum ?? '',
          homeDistrict: customer?.homeDistrict ?? '',
          homeCity: customer?.homeCity ?? '',
          homeState: customer?.homeState ?? '',
          homeComplement: customer?.homeComplement ?? ''
        } : {},
      emailContact: customer?.emailContactFlag && !customer.pmUnsubscribedEmail,
      webpushContact: customer?.webPushContactFlag ?? false,
      smsContact: customer?.smsContactFlag && !customer?.pmUnsubscribedSms,
      ...window.userIsFisicalPerson ? {
        lastName: customer?.lastName ?? '',
        ...regStepEnabled('data-nascimento').length ?
          { date: customer?.dateOfBirth ? parseDate(customer?.dateOfBirth) : 0 } : {},
        ...regStepEnabled('genero').length ?
          { gender: customer?.gender ?? '' } : {},
      } : {},
      ...regStepEnabled('category').length ? { businessCategory: customer?.businessCategory ?? '' } : {},
      ...regStepEnabled('profession').length ? { profession: customer?.profession ?? '' } : {}
    },
    validationSchema: validationSchema,
    onSubmit: async (value) => {
      //console.log('formik submit : ', value);

      document.body.style.cursor = 'wait';

      let phone = '+55' + value.mobilePhone.replace(/\D/g, "")

      let dateOfBirth = {}

      if (
        window.userIsFisicalPerson
        && regStepEnabled('data-nascimento').length
        && value.date
        && !customer.dateOfBirth
      ) {
        dateOfBirth = { dateOfBirth: new Date(formatISO(value.date as Date as Date, { representation: 'date' })).toISOString() }
      }

      let props: Customer = {

        ...phone == customer.mobilePhone ? {} : { mobilePhone: phone },

        ...value.homeEmail == customer.homeEmail ? {} : { homeEmail: value.homeEmail },

        ...dateOfBirth,

        ...value.emailContact == customer.emailContactFlag ? {} : { emailContactFlag: value.emailContact },

        ...value.webpushContact == customer.webPushContactFlag ? {} : { webPushContactFlag: value.webpushContact },

        ...!value.webpushContact ? { pdcDeviceIds: [], pushDeviceIds: [] } : {},

        ...value.smsContact == customer.smsContactFlag ? {} : { smsContactFlag: value.smsContact },

        //pmUnsubscribedEmail: !value.emailContact,

        //pmUnsubscribedSms: !value.smsContact,

        //pmRegisteredInPartnerProgram: true,

        ...customer.registeredInPartnerProgram == true ? {} : { registeredInPartnerProgram: true },

      }

      let propsToDelete: DeletableProps[] = []

      const pushFieldToPropsOrDelete = (prop: DeletableProps, val?: string, propsToAddtoDelete?: DeletableProps[]) => {
        let v = val || (value[prop] ?? '')
        if (v) {
          if (v != customer[prop]) {
            props[prop] = v
          }
        } else {
          propsToDelete = [...propsToDelete, prop, ...propsToAddtoDelete ?? []]
        }
      }

      if (window.userIsFisicalPerson) {
        pushFieldToPropsOrDelete('firstName')
        pushFieldToPropsOrDelete('lastName', value.lastName)

        if (regStepEnabled('genero').length) {
          pushFieldToPropsOrDelete('gender', value.gender)
        }
      }

      if (regStepEnabled('endereco').length) {
        pushFieldToPropsOrDelete('homeZip', value.homeZip?.replace(/\D/g, ""), ['homeStreet', 'homeNum', 'homeDistrict', 'homeCity', 'homeState', 'homeComplement']);

        ['homeStreet', 'homeNum', 'homeDistrict', 'homeCity', 'homeState', 'homeComplement'].forEach((prop) => {
          //@ts-ignore
          let v = ['homeNum', 'homeZip'].includes(prop) ? value[prop].replace(/\D/g, "") : value[prop]
          pushFieldToPropsOrDelete(prop as DeletableProps, v)
        })
      }

      if (regStepEnabled('category').length) {

        //console.log('value.businessCategory : ', value.businessCategory)

        pushFieldToPropsOrDelete('businessCategory', value.businessCategory)
      }

      if (regStepEnabled('profession').length) {
        pushFieldToPropsOrDelete('profession', value.profession)
      }

      //filter duplicate props
      propsToDelete = propsToDelete.filter((e, i, a) => i === a.indexOf(e))

      if (propsToDelete.length) {
        dispatch(deleteCustomerProps(propsToDelete))
      }

      const finishSubmit = () => {
        document.body.style.cursor = 'default'

        if (value.webpushContact) {
          pdcStore.set.checkNotificationsPermission('push_signup_update_checkbox_check')
        }

        formik?.setSubmitting(false);
        history.push(`/cadastro${search}`)
      }

      if (Object.keys(props).length) {
        dispatch(updateCustomer(props))
          .finally(finishSubmit)
      } else {
        finishSubmit()
      }
    },
  })

  const deleteUser = async () => {
    await dispatch(requestDeleteData())
  }

  useEffect(() => {
    if (customer?.deleteRequestDate) {
      history.push(`/unsubscription${search}`)
    }
  }, [customer])

  const [raw, set_raw] = useState<string | undefined>()

  const [value, set_value] = useState<Date | null>(null)

  useEffect(() => {
    if (
      (
        (
          raw?.length == 10
          || raw === undefined
        )
        && value
      )
      || (
        !raw?.length
        && raw !== undefined
        && !value
      )
    ) {
      formik.setFieldValue('date', value)
    }
  }, [
    raw,
    value,
  ])

  return (
    <>
      <form className="info_wrapper edit_form roll_in_from_bottom_animation" onSubmit={formik?.handleSubmit}>

        <Helmet>
          <title>Dados cadastrado</title>
          <meta name="description" content="Acesso ao cadastro do usuário." />
        </Helmet>

        <div className="info">
          {/* Left column */}
          <div className='info_column'>

            {/* CPF & Name */}
            <div className="info_block">

              <div className="info_block_header filled">
                {
                  (
                    !!customer?.customerId
                    &&
                    !!(
                      window.userIsFisicalPerson ?
                        customer?.firstName
                        :
                        customer?.firstName || customer?.lastName
                    )
                  ) ? <CheckFilledSVG /> : <CheckSVG />
                }
                Identificação
              </div>
              <div className="info_block_content">
                <div className="info_block_content_header">
                  CPF
                </div>
                <span className='info_block_content_data'>
                  {
                    customer?.customerId
                      ?
                      StringMask.apply(customer.customerId, '000.000.000-00')
                      :
                      missingInfo
                  }
                </span>

                <div className="info_block_content_header">
                  {
                    window.userIsFisicalPerson ? 'Nome' : 'Razão social'
                  }
                </div>
                <TextInput
                  id="firstName"
                  name="firstName"
                  placeholder="Primeiro nome"
                  //@ts-ignore
                  className={`${isNotMobile} ${formik?.touched.firstName && Boolean(formik?.errors.firstName) && 'invalid' || ''}`}
                  value={formik?.values.firstName}
                  onBlur={formik?.handleBlur}
                  onChange={formik?.handleChange}
                  error={formik?.touched.firstName && formik?.errors.firstName || ' '}

                  disabled={formik?.isSubmitting}
                />

                {/* lastName */}
                {
                  window.userIsFisicalPerson ?
                    <TextInput
                      id="lastName"
                      name="lastName"
                      placeholder="Sobrenome"
                      //@ts-ignore
                      className={`${isNotMobile}`}
                      value={formik?.values.lastName}
                      onBlur={formik?.handleBlur}
                      onChange={formik?.handleChange}
                      error=" "

                      disabled={formik?.isSubmitting}
                    />
                    : ''
                }


              </div>

            </div>

            {/* Phone & Email */}
            <div className="info_block">

              <div className="info_block_header">
                {
                  (
                    !!customer?.mobilePhone
                    &&
                    customer?.homeEmail
                  )
                    ? <CheckFilledSVG /> : <CheckSVG />
                }
                Contato
              </div>
              <div className="info_block_content">
                <div className="info_block_content_header">
                  Celular
                </div>
                {/* phone */}
                <InputMask mask="(99) 99999-9999"
                  value={formik?.values.mobilePhone}
                  onBlur={formik?.handleBlur}
                  onChange={formik?.handleChange}
                  disabled={formik?.isSubmitting}
                  maskChar={null}
                >
                  {(inputProps: any) =>
                    <TextInput
                      id="mobilePhone"
                      name="mobilePhone"
                      placeholder="Celular"
                      type="tel"
                      inputMode="numeric"
                      className={`${isNotMobile} ${formik?.touched.mobilePhone && Boolean(formik?.errors.mobilePhone) && 'invalid' || ''}`}
                      {...inputProps}

                      error={formik?.touched.mobilePhone && formik?.errors.mobilePhone || ' '}

                      disabled={formik?.isSubmitting}
                    />
                  }
                </InputMask>
                {/* SMS Contact Checkbox */}
                <Checkbox
                  className="rounded_checkbox"
                  checked={!!formik?.values.smsContact}
                  filledIn
                  name="smsContact"
                  id="smsContact"
                  label="Aceito receber mensagens de SMS no número informado."
                  //@ts-ignore
                  value={true}
                  onChange={formik?.handleChange}
                  disabled={formik?.isSubmitting}
                />

                <div className="info_block_content_header">
                  E-mail
                </div>
                {/* homeEmail */}
                <TextInput
                  id="homeEmail"
                  name="homeEmail"
                  placeholder="E-mail"
                  //@ts-ignore
                  className={`${isNotMobile} ${formik?.touched.homeEmail && Boolean(formik?.errors.homeEmail) && 'invalid' || ''}`}
                  value={formik?.values.homeEmail}
                  onBlur={formik?.handleBlur}
                  onChange={formik?.handleChange}
                  error={formik?.touched.homeEmail && formik?.errors.homeEmail || ' '}

                  disabled={formik?.isSubmitting}
                />
                {/* Email Contact Checkbox */}
                <Checkbox
                  className="rounded_checkbox"
                  checked={!!formik?.values.emailContact}
                  filledIn
                  name="emailContact"
                  id="emailContact"
                  label="Aceito receber mensagens de e-mail no endereço informado."
                  //@ts-ignore
                  value={false}
                  onChange={formik?.handleChange}
                  disabled={formik?.isSubmitting}
                />
                {/* Webpush Contact Checkbox */}
                <Checkbox
                  className="rounded_checkbox"
                  checked={!!formik?.values.webpushContact}
                  filledIn
                  //@ts-ignore
                  name="webpushContact"
                  id="webpushContact"
                  label="Quero receber notificações das melhores ofertas no meu navegador."
                  //@ts-ignore
                  value={false}
                  onChange={formik?.handleChange}
                  disabled={formik?.isSubmitting}
                />
              </div>

            </div>

            {/* Category */}
            {
              regStepEnabled('category').length ?
                <div className="info_block">
                  <div className="info_block_header filled">
                    {customer?.businessCategory ? <CheckFilledSVG /> : <CheckSVG />}
                    Categoria
                  </div>
                  <div className="info_block_content">
                    <TextInputWithSearchDropdown
                      placeholder="Selecione uma categoria"
                      initialValue={formik?.values.businessCategory}
                      options={window.parameters.category_options}
                      setSelectedItem={(v) => {
                        //console.log('setFieldValue businessCategory : ', v)
                        formik?.setFieldValue('businessCategory', v, false)
                      }}
                      classnames='CategoriaSelector dropdownIcon'
                      saveEmpty={true}
                    />
                    <div className="input-field"><span className="helper-text"></span></div>
                  </div>
                </div>
                : ''
            }

            {/* Profession */}
            {
              regStepEnabled('profession').length ?
                <div className="info_block">
                  <div className="info_block_header filled">
                    {customer?.profession ? <CheckFilledSVG /> : <CheckSVG />}
                    Profissão
                  </div>
                  <div className="info_block_content">
                    <TextInputWithSearchDropdown
                      placeholder="Selecione sua profissão"
                      initialValue={formik?.values.profession}
                      options={window.parameters.profession_options}
                      setSelectedItem={(v) => {
                        formik?.setFieldValue('profession', v, false)
                      }}
                      classnames='ProfessionSelector dropdownIcon'
                      saveEmpty={true}
                    />
                    <div className="input-field"><span className="helper-text"></span></div>
                  </div>
                </div>
                : ''
            }

          </div>

          {/* Right column */}
          <div className='info_column'>

            {/* Address */}
            {
              regStepEnabled('endereco').length ?
                <div className="info_block">

                  <div className="info_block_header">
                    {
                      customer?.homeZip
                        ?
                        <CheckFilledSVG />
                        :
                        <CheckSVG />
                    }
                    Endereço
                  </div>
                  <div className="info_block_content">

                    {/* CEP */}
                    <InputMask mask="99999-999"
                      value={formik?.values.homeZip}
                      onBlur={formik?.handleBlur}
                      onChange={formik?.handleChange}
                      disabled={formik?.isSubmitting}
                      maskChar={null}
                    >
                      {(inputProps: any) =>
                        <TextInput
                          id="homeZip"
                          name="homeZip"
                          placeholder="CEP"

                          type="tel"
                          inputMode="numeric"

                          className={`${isNotMobile} ${formik?.touched.homeZip && Boolean(formik?.errors.homeZip) && 'invalid' || ''}`}

                          {...inputProps}

                          error={formik?.touched.homeZip && formik?.errors.homeZip || ' '}
                          disabled={formik?.isSubmitting}
                        />
                      }
                    </InputMask>

                    {/* Rua */}
                    <TextInput
                      id="homeStreet"
                      name="homeStreet"
                      placeholder="Logradouro"
                      //@ts-ignore
                      className={`${isNotMobile} ${formik?.touched.homeStreet && Boolean(formik?.errors.homeStreet) && 'invalid' || ''}`}

                      value={formik?.values.homeStreet}

                      onBlur={formik?.handleBlur}
                      onChange={formik?.handleChange}

                      error={formik?.touched.homeStreet && formik?.errors.homeStreet || ' '}

                      disabled={formik?.isSubmitting}
                    />

                    {/* House Num */}
                    <InputMask mask="99999"
                      value={formik?.values.homeNum}
                      onBlur={formik?.handleBlur}
                      onChange={formik?.handleChange}
                      disabled={formik?.isSubmitting}
                      maskChar={null}
                    >
                      {(inputProps: any) =>
                        <TextInput
                          id="homeNum"
                          name="homeNum"
                          placeholder="Número"

                          className={`${isNotMobile} ${formik?.touched.homeNum && Boolean(formik?.errors.homeNum) && 'invalid' || ''}`}

                          {...inputProps}

                          error={formik?.touched.homeNum && formik?.errors.homeNum || ' '}

                          disabled={formik?.isSubmitting}
                        />
                      }
                    </InputMask>

                    {/* Complemento */}
                    <TextInput
                      id="homeComplement"
                      name="homeComplement"
                      placeholder="Complemento (opcional)"
                      //@ts-ignore
                      className={`${isNotMobile}`}

                      value={formik?.values.homeComplement}

                      onBlur={formik?.handleBlur}
                      onChange={formik?.handleChange}

                      error={formik?.touched.homeComplement && formik?.errors.homeComplement || ' '}

                      disabled={formik?.isSubmitting}
                    />

                    {/* Bairro */}
                    <TextInput
                      id="homeDistrict"
                      name="homeDistrict"
                      placeholder="Bairro"
                      //@ts-ignore
                      className={`${isNotMobile} ${formik?.touched.homeDistrict && Boolean(formik?.errors.homeDistrict) && 'invalid' || ''}`}

                      value={formik?.values.homeDistrict}

                      onBlur={formik?.handleBlur}
                      onChange={formik?.handleChange}

                      error={formik?.touched.homeDistrict && formik?.errors.homeDistrict || ' '}

                      disabled={formik?.isSubmitting}
                    />

                    {/* Cidade */}
                    <TextInput
                      id="homeCity"
                      name="homeCity"
                      placeholder="Cidade"
                      //@ts-ignore
                      className={`${isNotMobile} ${formik?.touched.homeCity && Boolean(formik?.errors.homeCity) && 'invalid' || ''}`}

                      value={formik?.values.homeCity}

                      onBlur={formik?.handleBlur}
                      onChange={formik?.handleChange}

                      error={formik?.touched.homeCity && formik?.errors.homeCity || ' '}

                      disabled={formik?.isSubmitting}
                    />

                    {/* UF */}
                    <Select
                      name="homeState"
                      id="homeState"

                      multiple={false}
                      options={{
                        classes: `${isNotMobile} select-restricted-height ${formik?.touched.homeState && Boolean(formik?.errors.homeState) && 'invalid' || ''}`,
                        dropdownOptions: {
                          alignment: 'left',
                          autoTrigger: true,
                          closeOnClick: true,
                          constrainWidth: true,
                          coverTrigger: false,
                          hover: false,
                          inDuration: 150,
                          onCloseStart: () => {
                            document.getElementById('homeState')?.parentElement?.classList.remove('expanded')
                          },
                          //onCloseEnd: null,
                          //onOpenEnd: null,
                          onOpenStart: (e) => {
                            remove_select_touch_end(e)
                            document.getElementById('homeState')?.parentElement?.classList.add('expanded')
                          },
                          outDuration: 250
                        }
                      }}

                      value={formik?.values.homeState}

                      //@ts-ignore
                      onBlur={formik?.handleBlur}
                      onChange={(e) => {
                        e.preventDefault()
                        e.stopPropagation()

                        remove_select_touch_end(e.target)
                        formik?.handleChange(e)
                      }}

                      error={formik?.touched.homeState && formik?.errors.homeState || ' '}

                      disabled={formik?.isSubmitting}
                    >
                      <option value="" disabled selected>Estado</option>

                      {['AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO', 'DF'].map((state, i) =>
                        <option key={`${state}-${i}`} value={state}>
                          {state}
                        </option>
                      )}
                    </Select>

                  </div>

                </div>

                : ''
            }

            {
              window.userIsFisicalPerson
                ?
                <>

                  {/* Birthday */}
                  {
                    regStepEnabled('data-nascimento').length ?
                      <div className="info_block">
                        <div className="info_block_header">
                          {
                            customer?.dateOfBirth
                              ?
                              <CheckFilledSVG />
                              :
                              <CheckSVG />
                          }
                          Nascimento
                        </div>
                        <div className="info_block_content">

                          {/* Date */}
                          <div className="input-field" id="DatePicker">
                            {/*@ts-ignore*/}
                            <DatePicker
                              ref={pickerRef}
                              customInput={
                                <InputMask
                                  mask="99/99/9999"
                                  maskChar={null}
                                  type="tel"
                                  inputMode="numeric"
                                />
                              }
                              dateFormat="dd/MM/yyyy"
                              selected={formik?.values.date || null}

                              onChangeRaw={(e) => {

                                set_raw(e.target?.value)

                                if (e.target?.value?.length == 10) {
                                  pickerRef.current?.setOpen(false)
                                } else {
                                  pickerRef.current?.setOpen(true)
                                }
                              }}

                              onChange={v => set_value(v)}

                              onBlur={() => {
                                formik?.setFieldTouched('date', true, true)
                              }}
                              placeholderText="DD/MM/AAAA"
                              className={`${isNotMobile} ${formik?.touched.date && Boolean(formik?.errors.date) && 'invalid' || ''}`}
                              showMonthDropdown
                              showYearDropdown
                              yearDropdownItemNumber={2022}
                              scrollableYearDropdown
                              locale={ptBR}
                              minDate={new Date("1900/01/01")}
                              maxDate={new Date()}
                              openToDate={formik?.values.date || new Date("1993/09/28")}
                              popperModifiers={[
                                {
                                  name: 'arrow',
                                  options: {
                                    padding: ({ popper, reference, placement }) => ({
                                      right: Math.min(popper.width, reference.width) - 24,
                                    }),
                                  },
                                },
                              ]}
                              disabled={!!customer.dateOfBirth}
                            />
                            <span className="helper-text">
                              {formik?.touched.date && formik?.errors.date || ' '}
                            </span>
                          </div>
                        </div>
                      </div>
                      : ''
                  }

                  {/* Gender */}
                  {
                    regStepEnabled('genero').length ?
                      <div className="info_block">
                        <div className="info_block_header">
                          {
                            customer?.gender
                              ?
                              <CheckFilledSVG />
                              :
                              <CheckSVG />
                          }
                          Gênero
                        </div>
                        <div className="info_block_content">

                          {/* gender */}
                          <Select
                            name="gender"
                            id="gender"

                            multiple={false}
                            options={{
                              classes: `${isNotMobile} ${formik?.touched.gender && Boolean(formik?.errors.gender) && 'invalid' || ''}`,
                              dropdownOptions: {
                                alignment: 'left',
                                autoTrigger: true,
                                closeOnClick: true,
                                constrainWidth: true,
                                coverTrigger: true,
                                hover: false,
                                inDuration: 150,
                                onCloseStart: () => {
                                  document.getElementById('gender')?.parentElement?.classList.remove('expanded')
                                },
                                onCloseEnd: () => {
                                  formik?.setFieldTouched('gender', true, true)
                                },
                                //onOpenEnd: null,
                                onOpenStart: (e) => {
                                  remove_select_touch_end(e)
                                  document.getElementById('gender')?.parentElement?.classList.add('expanded')
                                },
                                outDuration: 450
                              }
                            }}

                            value={formik?.values.gender}

                            onChange={e => {
                              e.preventDefault()
                              e.stopPropagation()

                              remove_select_touch_end(e.target)

                              formik?.handleChange(e)
                            }}

                            //onBlur={formik?.handleBlur}

                            disabled={formik?.isSubmitting}
                          >
                            <option value="" disabled selected>Selecione seu gênero</option>

                            {[
                              {
                                name: "Feminino",
                                val: 'F'
                              }, {
                                name: "Masculino",
                                val: 'M'
                              }, {
                                name: "Outro",
                                val: 'O'
                              }, {
                                name: "Prefiro não dizer",
                                val: 'P'
                              }].map((state, i) =>
                                <option key={`${state.name}-${i}`} value={state.val}>
                                  {state.name}
                                </option>
                              )}
                          </Select>
                          <span className="helper-text">
                            {formik?.touched.gender && formik?.errors.gender || ' '}
                          </span>
                        </div>
                      </div>
                      : ''
                  }
                </>
                : ''
            }

          </div>

        </div>

        <Button
          flat
          waves="light"
          className="white-text common_text_button"
          //@ts-ignore
          type="submit"
        >
          <DisableOverflowScrolling />
          Salvar minhas informações
        </Button>

        {
          window.parameters.deleteUserDisabled ?
            ''
            :
            <Button
              flat
              className="white primary_color_text common_text_button"
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                setOpenDeleteModal(true)
              }}
            >Cancelar minha conta</Button>
        }
      </form >

      <Modal
        id="delete-modal"
        open={openDeleteModal}

        options={{
          dismissible: true,
          onCloseEnd: () => setOpenDeleteModal(false)
        }}

        actions={[

          <Button flat modal="close" node="button" className="primary_background text_primary_background common_text_button"
            onClick={() => setOpenDeleteModal(false)}
          >
            Quero continuar recebendo ofertas
          </Button>,

          <Button flat modal="close" node="button" className="primary_color_text common_text_button"
            onClick={deleteUser}
          >
            Quero cancelar minha conta
          </Button>

        ]}

        header='Não fique de fora de nossas ofertas'

      >
        <p>
          Tem certeza que deseja cancelar a conta vinculada ao e-mail {customer?.email || customer?.homeEmail}?
        </p>

      </Modal>
    </>
  )
}