import { FC, useMemo, useEffect, useCallback } from "react"

import {
  useLocation,
} from "react-router-dom"

import { useAppDispatch, useAppSelector } from "@/app/hooks"

import {
  setPropzMediaStateParameter,
  updateCustomer,
} from "@/features/propzMedia/propzMediaSlice"

import { Button, Modal } from "react-materialize"

import { useMediaQuery } from 'react-responsive'

import './style.sass'

import {
  notificationsNotAvailable,
} from '@/utils/internal functions'

import {
  useStore,
} from '@assets/store'

import { pdcStore } from "@assets/store/pdcStore"

export const WebpushModal: FC = () => {

  const { pathname } = useLocation()

  const dispatch = useAppDispatch()

  const {
    showEmailSkippedPopup,
    showPhoneSkippedPopup,
  } = useAppSelector(
    (state) => state.propzMedia
  )

  const customer = useStore().app.customer()

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  const WebpushModalOpened = pdcStore.use.WebpushModalOpened()

  const UserWantsPWA = pdcStore.use.UserWantsPWA()

  useEffect(() => {
    //check if Notifications functionality available in user's browser
    if (WebpushModalOpened && notificationsNotAvailable()) {

      console.log('[WebpushModal] notificationsNotAvailable')

      pdcStore.set.WebpushModalOpened(false)

      pdcStore.set.deniedNotificationsModalState({
        continue: true,
        opened: false
      })
    }
  }, [WebpushModalOpened])

  const popupOpened = useMemo(() =>
    WebpushModalOpened
    && !showEmailSkippedPopup
    && !showPhoneSkippedPopup
    && !customer.webPushContactFlag
    , [
      WebpushModalOpened,
      showEmailSkippedPopup,
      showPhoneSkippedPopup,
      customer,
    ])

  useEffect(() => {
    if (popupOpened) {
      window.dataLayer.push({
        event: pathname.includes('cadastro') ? 'push_notification_signup_modal_view' : 'push_notification_pwa_app_modal_view',
        pwaTrackingId: window.pwaTrackingId,
      })
    }
  }, [popupOpened])

  const updateNotifications = useCallback((val: 0 | 1) => {

    pdcStore.set.WebpushModalOpened(false)

    if (val) {
      dispatch(updateCustomer({ webPushContactFlag: true }))

      pdcStore.set.checkNotificationsPermission(pathname.includes('cadastro') ? 'push_notification_signup_modal_click' : 'push_notification_pwa_app_modal_click')

    } else {

      // PUSH access rejected event
      window.dataLayer.push({
        event: "pwa - decline_notification",
        pwaTrackingId: window.pwaTrackingId
      })

      dispatch(updateCustomer({ webPushContactFlag: false }))

      //!!!!continue reg or pwa flow
      pdcStore.set.deniedNotificationsModalState({
        continue: true,
        opened: false
      })
    }
  }, [])

  useEffect(() => {
    if (UserWantsPWA && pathname != "/cadastro-aceite-webpush" && customer?.webPushContactFlag) {
      updateNotifications(1)
    }
  }, [UserWantsPWA])

  const regFlow = useMemo(() => pathname.includes("cadastro-") || pathname.includes("/webpush-pagina"), [pathname])

  return (
    <Modal
      actions={[
        <Button flat node="button" waves="light" className="primary_color_text"
          onClick={() => updateNotifications(0)}
        >
          {
            regFlow ?
              `Não quero receber${!isMobile ? ' as' : ''} ofertas exclusivas :(`
              :
              'Não quero receber as melhores ofertas'
          }
        </Button>,
        <Button flat node="button" waves="light" className="primary_color_background white-text big_button"
          onClick={() => {
            updateNotifications(1)
          }}
        >
          {
            regFlow ?
              `Quero receber${!isMobile ? ' notificações de' : ''} ofertas exclusivas`
              :
              'Quero a melhor experiência'
          }
        </Button>
      ]}

      header={
        regFlow ?
          'Quer ficar por dentro das nossas ofertas?'
          :
          'Ativar notificações'
      }
      open={popupOpened}

      id="web_push_modal"
      options={{
        dismissible: false,
        startingTop: "17%",
      }}
    >
      <p>
        {
          regFlow ?
            `Habilite notificações no seu navegador para receber ofertas selecionadas especialmente ${window.userIsFisicalPerson ? 'para' : ''} você!`
            :
            'Habilite notificações no seu navegador para uma melhor experiência no app'
        }
      </p>
    </Modal>
  )
}